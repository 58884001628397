// src/control/components/AddFileModal.js
import React, { useState, useEffect } from 'react';
import './AddFileModal.css';

import { FaTimes, FaCloudUploadAlt } from 'react-icons/fa';
import { bouncy } from 'ldrs';

import axios from '../helpers/axiosWithAuth';
//import axiosWithAdminId from '../helpers/axiosWithAdminId';
import axiosClean from 'axios';

const AddFileModal = ({
  isOpen,
  onClose,
  youtubeUrl,
  setYoutubeUrl,
  audio,
  setAudio,
  document,
  setDocument,
  uploadStatus,
  setUploadStatus,
  transcription,
  setTranscription,
  fileSourceType,
  setFileSourceType,
  selectedPeople,
  setSelectedPeople,
  addFileName,
  setAddFileName,
  selectedPersonId,
  isDocumentSelected,
  setIsDocumentSelected,
  isAudioFileSelected,
  setIsAudioFileSelected,
  resetFileForm,
  onEditTranscriptionFile,
  description,
  setDescription,
  keywords,
  setKeywords,
  setIsFilesQueued,
  peopleList,
  fetchFilesForPerson,
  fetchPersonFilesUpdateStatus,
}) => {
  const [filteredPeopleList, setFilteredPeopleList] = useState([]);
  const [peopleSearchInput, setPeopleSearchInput] = useState('');
  const [personToAdd, setPersonToAdd] = useState(null);

  bouncy.register();

  useEffect(() => {
    const normalizedSelectedPersonId = String(selectedPersonId);
    setFilteredPeopleList(peopleList.filter(person => !selectedPeople.includes(String(person.id)) && String(person.id) !== normalizedSelectedPersonId));
  }, [peopleList, selectedPeople, selectedPersonId]);

  useEffect(() => {
    if (isOpen && selectedPersonId) {
      setSelectedPeople([selectedPersonId]);
      setFilteredPeopleList(peopleList.filter(person => String(person.id) !== String(selectedPersonId)));
    }
  }, [isOpen, selectedPersonId, setSelectedPeople, peopleList]);

  useEffect(() => {
    if (personToAdd) {
      setSelectedPeople(prevSelectedPeople => {
        const updatedSelectedPeople = [...prevSelectedPeople, personToAdd];
        setFilteredPeopleList(peopleList.filter(p => !updatedSelectedPeople.includes(String(p.id))));
        return updatedSelectedPeople;
      });
      setPersonToAdd(null); // Reset the queue
    }
  }, [personToAdd, peopleList, selectedPeople]);

  if (!isOpen) return null;

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  //const SERVERLESS_FUNCTION_URL = "https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-2bd91dce-81fc-4bee-a77c-c6e12d6be5a4/transcription/function";

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocument(file);
      setIsDocumentSelected(true);
    } else {
      setIsDocumentSelected(false);
    }
  };

  const uploadDocument = async () => {
    if (!document) {
      alert('Please select a document first.');
      return;
    }

    const formData = new FormData();
    formData.append('document', document);
    formData.append('originalFilename', addFileName);
    const fileName = addFileName.replace(/\s+/g, '_').replace(/"/g, '');
    formData.append('userProvidedFileName', fileName);
    const selectedPeopleIds = selectedPeople.sort((a, b) => a - b).join('-');
    formData.append('selectedPeopleIds', selectedPeopleIds);
    const selectedPeopleNames = selectedPeople.map(id => {
      const person = peopleList.find(p => String(p.id) === String(id));
      return person ? `${person.name} ${person.surname}` : '';
    }).join(', ');
    formData.append('selectedPeople', selectedPeopleNames);
    // if fileSourceType is set to Research Paper specifically, then convert to 'Research' instead
    formData.append('fileSourceType', fileSourceType === 'Research Paper' ? 'Research' : fileSourceType);

    try {
      setUploadStatus('Uploading...');
      const response = await axios.post(`${API_BASE_URL}/upload-document`, formData);

      if (response.data && response.data.path) {
        setTranscription(response.data.path);
        fetchFilesForPerson(selectedPersonId); // Trigger fetchFilesForPerson
        fetchPersonFilesUpdateStatus(); // Trigger fetchPersonFilesUpdateStatus
      }
      setUploadStatus('Done');
      setIsDocumentSelected(false);
      alert('Document uploaded successfully!');
    } catch (error) {
      setUploadStatus('Error');
      console.error('Error in document upload', error);
      alert('Failed to upload the document.');
    }
  };

  const handleYoutubeSubmit = (e) => {
    e.preventDefault();
    const modifiedUrl = youtubeUrl.replace('youtube', 'youtubemz');
    window.open(modifiedUrl, '_blank');
  };

  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAudio(file);
      setIsAudioFileSelected(true);
    } else {
      setIsAudioFileSelected(false);
    }
  };


  const getPresignedUrl = async (fileName, fileType) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/generate_presigned_url`, {
        file_name: fileName,
        file_type: fileType
      });
      return response.data;
    } catch (error) {
      console.error('Error generating presigned URL', error);
      alert('Failed to generate presigned URL.');
      return null;
    }
  };
  
  const uploadFile = async (url, formData) => {
    try {
      const response = await axiosClean.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.status === 204;
    } catch (error) {
      console.error('Error uploading file', error);
      return false;
    }
  };
  
  const startTranscription = async (transcriptionData) => {
    const axiosConfig = {
      timeout: 1800000, // 30 minutes
    };
  
    try {
      const response = await axios.post(`${API_BASE_URL}/transcribe-audio`, transcriptionData, axiosConfig);
      return response.data && response.data.task_id;
    } catch (error) {
      console.error('Error starting transcription', error);
      return null;
    }
  };
  
  
  const uploadAndTranscribeAudio = async () => {
    if (!audio) {
      alert('Please select an audio file first.');
      return;
    }
    if (!addFileName.trim()) {
      alert('Please enter a filename.');
      return;
    }
  
    const presignedData = await getPresignedUrl(audio.name, audio.type);
    if (!presignedData) return;
  
    const { url, fields, s3_key } = presignedData;
    const formData = new FormData();
    Object.keys(fields).forEach(key => formData.append(key, fields[key]));
    formData.append('file', audio);
  
    setUploadStatus('Uploading...');
    const uploadSuccess = await uploadFile(url, formData);
  
    if (!uploadSuccess) {
      setUploadStatus('Error');
      alert('Failed to upload the audio file.');
      return;
    }
    
    const fileUrl = `https://vitalise.fra1.digitaloceanspaces.com/${s3_key}`;
    const selectedPeopleIds = selectedPeople.join(',');
    const transcriptionData = {
      file_name: audio.name,
      s3_key: s3_key,
      originalFilename: addFileName,
      selectedPeople: selectedPeople.map(id => {
        const person = peopleList.find(p => String(p.id) === String(id));
        return person ? `${person.name} ${person.surname}` : '';
      }).join(', '),
      selectedPeopleIds: selectedPeople.join(','),
      fileSourceType: fileSourceType,
      description: description,
      keywords: keywords
    };

    // Call endpoint to add the file entry to Files DB
    try {
      const response = await axios.post(`${API_BASE_URL}/add-audiofile-entry`, {
        file_name: audio.name,
        file_url: fileUrl,
        selectedPeopleIds: selectedPeopleIds,
        fileSourceType: fileSourceType,
        userProvidedFileName: addFileName
      });

      if (response.status !== 200) {
        alert('Failed to add file entry');
        return;
      }
    } catch (error) {
      console.error('Error adding file entry', error);
      alert('Failed to add file entry.');
      return;
    }
  
    onClose();
    setIsFilesQueued(true);
    alert('Audio file uploaded successfully. Transcription has been queued.');
    resetFileForm();
    setIsDocumentSelected(false);
    setIsAudioFileSelected(false);
    const taskId = await startTranscription(transcriptionData);
  
  };
  
  





  const handleCloseModal = () => {
    onClose();
    resetFileForm();
    setIsDocumentSelected(false);
    setIsAudioFileSelected(false);
    setSelectedPeople([]);
    setPeopleSearchInput('');
  };

  const renderSelectedPeople = () => {
    return (
      <div className="selected-person-grid">
        {selectedPeople.map(id => {
          const person = peopleList.find(p => String(p.id) === String(id));
          if (!person) return null;
          return (
            <div key={id} className="selected-person" onClick={() => removePerson(id)}>
              {person.name} {person.surname}
            </div>
          );
        })}
      </div>
    );
  };

  const handlePeopleSearchChange = (e) => {
    const input = e.target.value.toLowerCase();
    setPeopleSearchInput(input);
    
    if (input.trim() !== '') {
      const filtered = peopleList.filter(person =>
        `${person.name} ${person.surname}`.toLowerCase().includes(input)
      );
      setFilteredPeopleList(filtered);
    } else {
        setFilteredPeopleList(peopleList.filter(p => !selectedPeople.includes(String(p.id))));
    }
  };

  const selectPersonFromSearch = (id) => {
    const normalizedId = String(id);
    const normalizedSelectedPersonId = String(selectedPersonId);
    
    if (normalizedId === normalizedSelectedPersonId || selectedPeople.includes(normalizedId)) {
      alert("This person is already selected.");
      return;
    }
    
    setPeopleSearchInput('');
    setPersonToAdd(normalizedId);
  };

  const removePerson = (id) => {
    const normalizedId = String(id);
    const normalizedSelectedPersonId = String(selectedPersonId);
  
    if (normalizedId === normalizedSelectedPersonId) {
      alert("Cannot remove the primary selected person.");
      return;
    }
    
    setPeopleSearchInput('');
    setSelectedPeople(prevSelectedPeople => {
      const updatedSelectedPeople = prevSelectedPeople.filter(personId => String(personId) !== normalizedId);
      setFilteredPeopleList(peopleList.filter(p => !updatedSelectedPeople.includes(String(p.id))));
      return updatedSelectedPeople;
    });
  };

  const renderPersonSmallList = (person) => {
    return (
      <div key={person.id} className="add-file-person-container" onClick={() => selectPersonFromSearch(person.id)}>
        <div>
          <img
            src={person.image_url}
            alt={`${person.name} ${person.surname}`}
            className="add-file-person-image"
          />
          <div className="person-details">
            <h4 className="person-name">{person.name}</h4>
            <h4 className="person-surname">{person.surname ? person.surname.split(' ')[0] : ''}</h4>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="files-modal">
      <div className="files-modal-content">
        <div className="config-icons">
          <FaTimes title="Close" className='config-icon' onClick={handleCloseModal} />
        </div>
        <h3 className="subsection-title">Add File</h3>

        {uploadStatus !== 'Done' && (
          <>
            <div className="files-section">
              <h3 className="files-title">Source type</h3>
              <select className="files-select" value={fileSourceType} onChange={(e) => setFileSourceType(e.target.value)}>
                <option value="Podcast">Podcast / Interview</option>
                <option value="Book">Book</option>
                <option value="Research Paper">Research Paper</option>
              </select>
            </div>

            <div className="files-section">
              <h3 className="files-title">People</h3>
              <div>{renderSelectedPeople()}</div>
              <div className="configuration-selectors">
                <input
                  type="search"
                  placeholder="Search by name or surname..."
                  className="files-search-input"
                  value={peopleSearchInput}
                  onChange={handlePeopleSearchChange}
                />
              </div>
              <div className="add-file-people-slider">
                {filteredPeopleList.map(person => renderPersonSmallList(person))}
              </div>
            </div>

            <div className="files-section">
              <h3 className="files-title">Filename</h3>
              <input
                type="text"
                maxLength="90"
                placeholder="Example: How & Why to Get Weekly Zone 2 Cardio Workouts"
                value={addFileName}
                onChange={(e) => setAddFileName(e.target.value)}
                className='add-file-textinput'
              />
            </div>

            {fileSourceType === 'Podcast' && (
              <>
                <div className="addfiles-row">
                  <div className="files-section column">
                    <h3 className="files-small-title">A short description of the source {description.length}/58</h3>
                    <h3 className="files-small-title">(Optional)</h3>
                    <input
                      type="text"
                      maxLength="58"
                      placeholder="Conversation about x, y, z..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className='add-file-textinput'
                    />
                  </div>

                  <div className="files-section column">
                    <h3 className="files-small-title">Important keywords & acronyms from the source {keywords.length}/58</h3>
                    <h3 className="files-small-title">(Optional)</h3>
                    <input
                      type="text"
                      maxLength="58"
                      placeholder="VO2 max, HIIT, Zone 2, Cardio, etc."
                      value={keywords}
                      onChange={(e) => setKeywords(e.target.value)}
                      className='add-file-textinput'
                    />
                  </div>
                </div>

                <div className="files-section">
                  <h3 className="files-title">Input Youtube URL</h3>
                  <form onSubmit={handleYoutubeSubmit}>
                    <input className="add-file-textinput" type="text" placeholder="Paste Youtube URL" value={youtubeUrl} onChange={(e) => setYoutubeUrl(e.target.value)} />
                    <button className="wide-button" type="submit">Submit</button>
                  </form>
                </div>

                <div className="files-section">
                  <h3 className="files-title">Upload .mp3 Audio</h3>
                  <div>{uploadStatus}</div>
                  <div className="upload-loading-icon">
                    {uploadStatus === 'Uploading...' && <l-bouncy color="var(--brand-identity-color)" speed="1.5" size="50"></l-bouncy>}
                  </div>
                  <div className="image-submit-container">
                    <label
                      className={`file-upload-label ${isAudioFileSelected ? 'file-selected' : ''}`}
                    >
                      {isAudioFileSelected ? 'File selected' : 'Choose file...'}
                      <input
                        type="file"
                        accept=".mp3"
                        onChange={handleAudioChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                    {isAudioFileSelected && (
                      <>
                      <FaCloudUploadAlt
                        title="Transcribe"
                        className='submit-icon'
                        onClick={uploadAndTranscribeAudio}
                      />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {fileSourceType !== 'Podcast' && (
              <>
                <div className="files-section">
                  <h3 className="files-title">Upload file</h3>
                  <div className="image-submit-container">
                    <label
                      className={`file-upload-label ${isDocumentSelected ? 'file-selected' : ''}`}
                    >
                      {isDocumentSelected ? 'File selected' : 'Choose pdf or txt...'}
                      <input
                        type="file"
                        accept=".pdf,.txt"
                        onChange={handleDocumentChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                    <FaCloudUploadAlt
                      title="Upload"
                      className='submit-icon'
                      onClick={uploadDocument}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {uploadStatus === 'Done' && transcription && (
          <div className="files-section">
            <div className="transcription-container">
              <h3 className="files-title">Transcription</h3>
              <div onClick={() => {
                const transcriptionFileName = transcription ? transcription.split('/').pop() : 'N/A';
                onEditTranscriptionFile({ name: transcriptionFileName, url: transcription });
                fetchFilesForPerson(selectedPersonId);
                //setIsAudioFileSelected(false);
                resetFileForm();
              }}
                className="transcribed-file-item"
                title={transcription ? transcription.split('/').pop() : 'N/A'}
              >
                {transcription ? transcription.split('/').pop() : 'N/A'}
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default AddFileModal;
