// src/other/VitaliseReleaseNotes.js
export const VitaliseReleaseNotes = [
    {
      version: '0.1.20',
      sections: [
        {
          title: 'First Stable Release',
          bullets: [
            'Personalized health recommendations.',
            'AI-driven insights.',
            'Create unique digital assistant.',
          ]
        }
      ]
    },
    {
      version: 'Coming Soon',
      sections: [
        {
          title: 'New Features and Fixes',
          bullets: [
            'Integration with wearables and health devices.',
            'Bug fixes and performance improvements.',
            'Add health plan functionality (workouts, diet plans, etc).',
          ]
        },
        {
            title: 'And More!',
            bullets: [
              '...',
            ]
        }
      ]
    },
  ];