// Header.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import LoginModal from './LoginModal';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (isLoggedIn) => {
    setIsModalOpen(false);
    if (isLoggedIn) {
      navigate('/control');
    }
  };

  return (
    <header className="home-header">
      <Link to="/" className="home-icon-link">
        <FaHome className="home-icon" />
      </Link>
      <button className="control-panel-button" onClick={() => setIsModalOpen(true)}>CONTROL PANEL</button>
      <LoginModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onLogin={handleLogin} 
      />
    </header>
  );
};

export default Header;
