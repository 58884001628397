// src/control/Dashboard.js
import React, { useState, useEffect, useCallback } from 'react';
import './Dashboard.css';

import { FaCircle, FaTimes } from 'react-icons/fa';
import Sidebar from './components/Sidebar';
import { Bar, Line, Radar } from 'react-chartjs-2';
import { getRadarChartData, radarChartOptions } from './config/radarChartConfig';
import { dailyUserStatsData, dailyUserStatsOptions } from './config/kpiChartConfig';
import { getFilesInfoChartsConfig } from './config/filesInfoChartConfig';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { kpiData } from './data/kpiData';
import categoriesOfExpertise from './config/expertiseCategories';

import { useNavigate } from 'react-router-dom';

import axios from './helpers/axiosWithAuth';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, TimeScale, Tooltip, Legend);

const Dashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const [isLoadingPeople, setIsLoadingPeople] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('ID');
  const [filesInfo, setFilesInfo] = useState([]);
  const [assistantFiles, setAssistantFiles] = useState([]);
  const [isLoadingAssistants, setIsLoadingAssistants] = useState(false);

  const [isViewAssistantModalOpen, setIsViewAssistantModalOpen] = useState(false);
  const [viewAssistantFileContent, setViewAssistantFileContent] = useState('');
  const [viewAssistantFilePersons, setViewAssistantFilePersons] = useState('');
  const [scienceFileUpdated, setScienceFileUpdated] = useState(false);
  const [personFileUpdateStatus, setPersonFileUpdateStatus] = useState({});

  const categories_of_expertise = categoriesOfExpertise;
  const [radarChartData, setRadarChartData] = useState([]);

  const filterOptions = ['ID', 'Alphabetical'];
  const assistantFilter = ['All', 'Updated', 'Outdated'];
  const [currentAssistantFilter, setCurrentAssistantFilter] = useState('All');


  const {
    dataForFilesCountChart,
    optionsForFilesCountChart,
    dataForTotalCharactersChart,
    optionsForTotalCharactersChart
  } = getFilesInfoChartsConfig(filesInfo);


  const fetchPeople = useCallback(() => {
    setIsLoadingPeople(true);
    axios.get(`${API_BASE_URL}/get-people`)
      .then(response => {
        // Set the peopleList without sorting
        setPeopleList(response.data);
        setRadarChartData(getRadarChartData(categories_of_expertise, response.data));
      })
      .catch(error => {
        console.error("There was an error fetching the people: ", error);
        setIsLoadingPeople(false);
      })
      .finally(() => {
        setIsLoadingPeople(false);
      });
  }, [API_BASE_URL, categories_of_expertise]);
  

  useEffect(() => {
    fetchPeople();
  }, [fetchPeople]);

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setCurrentFilter(filter);
  
    let sortedList = [];
    if (filter === 'Alphabetical') {
      sortedList = [...peopleList].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedList = [...peopleList].sort((a, b) => a.id - b.id);
    }
    setPeopleList(sortedList);
  };

  const handleAssistantFilterChange = (e) => {
    const filter = e.target.value;
    setCurrentAssistantFilter(filter);
  };
  
  const getFilteredAssistantFiles = () => {
    if (currentAssistantFilter === 'Updated') {
      return assistantFiles.filter(file => 
        (file.type === 'science' && scienceFileUpdated) || 
        personFileUpdateStatus[file.id]
      );
    } else if (currentAssistantFilter === 'Outdated') {
      return assistantFiles.filter(file => 
        (file.type === 'science' && !scienceFileUpdated) || 
        !personFileUpdateStatus[file.id]
      );
    }
    return assistantFiles;
  };  

  const fetchAssistantFiles = useCallback(() => {
    setIsLoadingAssistants(true);
    axios.get(`${API_BASE_URL}/get-assistant-files`)
      .then(response => {
        setAssistantFiles(response.data.assistantFiles || []);
      })
      .catch(error => {
        console.error("There was an error fetching the assistant files: ", error);
        setIsLoadingAssistants(false);
      })
      .finally(() => {
        setIsLoadingAssistants(false);
      });
    }, [API_BASE_URL]);

  const fetchFilesInfo = useCallback(async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-files-info`);
        setFilesInfo(response.data);
      } catch (error) {
        console.error("Failed to fetch files info:", error);
      }
    }, [API_BASE_URL]);

  useEffect(() => {
      fetchAssistantFiles();
      fetchFilesInfo();
  }, [fetchAssistantFiles, fetchFilesInfo]);


  const fetchPersonFilesUpdateStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-person-files-update-status`);
      if (response.data) {
        setPersonFileUpdateStatus(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch person files update status:', error);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
      // Fetch the person files update status when the component mounts
      fetchPersonFilesUpdateStatus();
  }, [fetchPersonFilesUpdateStatus]);

  const fetchScienceFileUpdateStatus = useCallback(async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-science-file-update-status`);
        if (response.data) {
          setScienceFileUpdated(response.data.science_updated);
        }
      } catch (error) {
        console.error('Failed to fetch science file update status:', error);
      }
    }, [API_BASE_URL]);

  useEffect(() => {
      // Fetch the science file update status when the component mounts
      fetchScienceFileUpdateStatus();
  }, [fetchScienceFileUpdateStatus]);

  const handleViewAssistantContent = async (file, fileType) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-assistant-file-content/${fileType}/${file.name}`);
      setViewAssistantFileContent({ name: file.name, content: response.data.content });
      const ids = file.name.replace('.txt', '').split('-').map(Number);
      const personsResponse = await axios.post(`${API_BASE_URL}/get-persons-by-ids`, { ids }, {
        headers: { 'Content-Type': 'application/json' }
      });
      const namesString = personsResponse.data.map(person => (
        <button
          key={person.id}
          className="person-name-button"
          onClick={() => navigate(`/files?selectedPersonId=${person.id}`)}
        >
          {person.name} {person.surname}
        </button>
      ));
      setViewAssistantFilePersons(namesString);
      setIsViewAssistantModalOpen(true);
    } catch (error) {
      console.error("Error fetching assistant file content:", error);
      alert("Failed to load assistant file content.");
    }
  };   
  

  return (
    <div className="dashboard">
      <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
      <div className={`dashboard-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <h1 className='page-title'>Vitalise.ai Dashboard</h1>

        <div className="kpi-section">
          {kpiData.userKPIs.map((kpi, index) => (
            <div key={index} className="kpi-box">
              <h3>{kpi.title}</h3>
              <div className="kpi-divider"></div>
              <p>Total: {kpi.total}</p>
              <p>DAU: {kpi.DAU}</p>
              <p>MAU: {kpi.MAU}</p>
            </div>
          ))}
        </div>

        <div className="kpi-section">
          {kpiData.otherKPIs.map((kpi, index) => (
            <div key={index} className="kpi-box">
              <h3>{kpi.title}</h3>
              <div className="kpi-divider"></div>
              <p>{kpi.value}</p>
            </div>
          ))}
        </div>

        <div className="kpi-section">
          {kpiData.additionalKPIs.map((kpi, index) => (
            <div key={index} className="kpi-box">
              <h3>{kpi.title}</h3>
              <div className="kpi-divider"></div>
              <p>{kpi.value}</p>
            </div>
          ))}
        </div>

        <div className="daily-stats-section">
          <Line data={dailyUserStatsData} options={dailyUserStatsOptions} />
        </div>

        <div className="overview-section">
          <div className="overview-subsection">
            <label className='subsection-title'>People</label>
            <div className="configuration-selectors">
              <select className="filter-dropdown" onChange={handleFilterChange} value={currentFilter}>
                {filterOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <div className="content-list">
              {isLoadingPeople ? (
                <div className="no-files-message">Loading people...</div>
              ) : (
                peopleList.map(person => (
                  <div key={person.id}>{person.name} {person.surname} {person.id}</div>
                ))
              )}
            </div>
          </div>

          <div className="overview-subsection">
            <label className='subsection-title'>Assistants</label>
            <div className="configuration-selectors">
              <select className="filter-dropdown" onChange={handleAssistantFilterChange} value={currentAssistantFilter}>
                {assistantFilter.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <div className="content-list">
              {isLoadingAssistants ? (
                <div className="no-files-message">Loading assistants...</div>
              ) : (
                getFilteredAssistantFiles().map((file, index) => (
                  <button key={index} className="dashbord-file-item" title={file.name} onClick={() => handleViewAssistantContent(file, file.type)}>
                    <FaCircle color={file.type === 'science' ? (scienceFileUpdated ? "#00FF00" : "#FFFF33") : (personFileUpdateStatus[file.id] ? "#00FF00" : "#FFFF33")} className="file-status-icon" />
                    <span>{file.name}</span>
                  </button>
                ))
              )}
            </div>
            {isViewAssistantModalOpen && (
              <div className="view-file-modal">
                <div className="view-file-modal-content">
                  <div className="config-icons">
                    <FaTimes title="Close" className='config-icon' onClick={() => setIsViewAssistantModalOpen(false)} />
                  </div>
                  <h3 className="subsection-selection-title">Viewing File: {viewAssistantFileContent.name}</h3>
                  <p>{viewAssistantFilePersons}</p>
                  <textarea readOnly value={viewAssistantFileContent.content} style={{ width: '100%', height: '300px' }}></textarea>
                </div>
              </div>
            )}
          </div>
        </div>
        
        <label className="section-title">Number of people per expertise</label>
        <div className="overview-section">
          <div className="radar-charts-grid">
            {radarChartData.map((data, index) => (
              <div key={index} className="radar-chart-container">
                <Radar data={data} options={radarChartOptions} />
              </div>
            ))}
          </div>
        </div>
        
        <label className="section-title">Data Quantities</label>
        <div className="graphics-section">
          <div className="chart-container">
            <Bar data={dataForFilesCountChart} options={optionsForFilesCountChart} />
          </div>
          <div className="chart-container">
            <Bar data={dataForTotalCharactersChart} options={optionsForTotalCharactersChart} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
