// src/control/data/kpiData.js

export const kpiData = {
    userKPIs: [
      { title: 'Users (total)', total: 1000, DAU: 200, MAU: 600 },
      { title: 'Users (free)', total: 600, DAU: 120, MAU: 300 },
      { title: 'Users (standard)', total: 300, DAU: 50, MAU: 150 },
      { title: 'Users (premium)', total: 100, DAU: 30, MAU: 80 },
    ],
    otherKPIs: [
      { title: 'Assistants', value: 50 },
      { title: 'Queries', value: 20000 },
      { title: 'Threads', value: 8000 },
      { title: 'Function Calls', value: 5000 },
    ],
    additionalKPIs: [
      { title: 'Churn', value: '5%' },
      { title: 'Monthly New Users', value: 300 },
      { title: 'Retention Rate', value: '95%' },
      { title: 'MRR', value: '$20,000' },
      { title: 'ARR', value: '$240,000' },
    ],
    dailyUserStats: [
      { date: '2024-05-01', free: 560, standard: 280, premium: 80 },
      { date: '2024-05-02', free: 570, standard: 285, premium: 85 },
      { date: '2024-05-03', free: 580, standard: 290, premium: 90 },
      { date: '2024-05-04', free: 590, standard: 295, premium: 95 },
      { date: '2024-05-05', free: 600, standard: 300, premium: 100 },
      { date: '2024-05-06', free: 610, standard: 305, premium: 105 },
      { date: '2024-05-07', free: 620, standard: 310, premium: 110 },
      { date: '2024-05-08', free: 630, standard: 315, premium: 115 },
      { date: '2024-05-09', free: 640, standard: 320, premium: 120 },
      { date: '2024-05-10', free: 650, standard: 325, premium: 125 },
      { date: '2024-05-11', free: 660, standard: 330, premium: 130 },
      { date: '2024-05-12', free: 670, standard: 335, premium: 135 },
      { date: '2024-05-13', free: 680, standard: 340, premium: 140 },
      { date: '2024-05-14', free: 690, standard: 345, premium: 145 },
      { date: '2024-05-15', free: 700, standard: 350, premium: 150 },
      { date: '2024-05-16', free: 710, standard: 355, premium: 155 },
      { date: '2024-05-17', free: 720, standard: 360, premium: 160 },
      { date: '2024-05-18', free: 730, standard: 365, premium: 165 },
      { date: '2024-05-19', free: 740, standard: 370, premium: 170 },
      { date: '2024-05-20', free: 750, standard: 375, premium: 175 },
      { date: '2024-05-21', free: 760, standard: 380, premium: 180 },
      { date: '2024-05-22', free: 770, standard: 385, premium: 185 },
      { date: '2024-05-23', free: 780, standard: 390, premium: 190 },
      { date: '2024-05-24', free: 790, standard: 395, premium: 195 },
      { date: '2024-05-25', free: 800, standard: 400, premium: 200 },
      { date: '2024-05-26', free: 810, standard: 405, premium: 205 },
      { date: '2024-05-27', free: 820, standard: 410, premium: 210 },
      { date: '2024-05-28', free: 830, standard: 415, premium: 215 },
      { date: '2024-05-29', free: 840, standard: 420, premium: 220 },
      { date: '2024-05-30', free: 850, standard: 425, premium: 225 },
      { date: '2024-05-31', free: 860, standard: 430, premium: 230 },
      { date: '2024-06-01', free: 870, standard: 435, premium: 235 },
    ],
  };
  