import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DataStatsSection.css';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Tooltip as ReactTooltip } from 'react-tooltip';

// Add this function at the top of your file or in a separate utility file
const iso3ToIso2 = {
    '004': 'AF', '008': 'AL', '010': 'AQ', '012': 'DZ', '016': 'AS', '020': 'AD', '024': 'AO', '028': 'AG', '031': 'AZ', '032': 'AR',
    '036': 'AU', '040': 'AT', '044': 'BS', '048': 'BH', '050': 'BD', '051': 'AM', '052': 'BB', '056': 'BE', '060': 'BM', '064': 'BT',
    '068': 'BO', '070': 'BA', '072': 'BW', '074': 'BV', '076': 'BR', '084': 'BZ', '086': 'IO', '090': 'SB', '092': 'VG', '096': 'BN',
    '100': 'BG', '104': 'MM', '108': 'BI', '112': 'BY', '116': 'KH', '120': 'CM', '124': 'CA', '132': 'CV', '136': 'KY', '140': 'CF',
    '144': 'LK', '148': 'TD', '152': 'CL', '156': 'CN', '158': 'TW', '162': 'CX', '166': 'CC', '170': 'CO', '174': 'KM', '175': 'YT',
    '178': 'CG', '180': 'CD', '184': 'CK', '188': 'CR', '191': 'HR', '192': 'CU', '196': 'CY', '203': 'CZ', '204': 'BJ', '208': 'DK',
    '212': 'DM', '214': 'DO', '218': 'EC', '222': 'SV', '226': 'GQ', '231': 'ET', '232': 'ER', '233': 'EE', '234': 'FO', '238': 'FK',
    '239': 'GS', '242': 'FJ', '246': 'FI', '248': 'AX', '250': 'FR', '254': 'GF', '258': 'PF', '260': 'TF', '262': 'DJ', '266': 'GA',
    '268': 'GE', '270': 'GM', '275': 'PS', '276': 'DE', '288': 'GH', '292': 'GI', '296': 'KI', '300': 'GR', '304': 'GL', '308': 'GD',
    '312': 'GP', '316': 'GU', '320': 'GT', '324': 'GN', '328': 'GY', '332': 'HT', '334': 'HM', '336': 'VA', '340': 'HN', '344': 'HK',
    '348': 'HU', '352': 'IS', '356': 'IN', '360': 'ID', '364': 'IR', '368': 'IQ', '372': 'IE', '376': 'IL', '380': 'IT', '384': 'CI',
    '388': 'JM', '392': 'JP', '398': 'KZ', '400': 'JO', '404': 'KE', '408': 'KP', '410': 'KR', '414': 'KW', '417': 'KG', '418': 'LA',
    '422': 'LB', '426': 'LS', '428': 'LV', '430': 'LR', '434': 'LY', '438': 'LI', '440': 'LT', '442': 'LU', '446': 'MO', '450': 'MG',
    '454': 'MW', '458': 'MY', '462': 'MV', '466': 'ML', '470': 'MT', '474': 'MQ', '478': 'MR', '480': 'MU', '484': 'MX', '492': 'MC',
    '496': 'MN', '498': 'MD', '499': 'ME', '500': 'MS', '504': 'MA', '508': 'MZ', '512': 'OM', '516': 'NA', '520': 'NR', '524': 'NP',
    '528': 'NL', '531': 'CW', '533': 'AW', '534': 'SX', '535': 'BQ', '540': 'NC', '548': 'VU', '554': 'NZ', '558': 'NI', '562': 'NE',
    '566': 'NG', '570': 'NU', '574': 'NF', '578': 'NO', '580': 'MP', '581': 'UM', '583': 'FM', '584': 'MH', '585': 'PW', '586': 'PK',
    '591': 'PA', '598': 'PG', '600': 'PY', '604': 'PE', '608': 'PH', '612': 'PN', '616': 'PL', '620': 'PT', '624': 'GW', '626': 'TL',
    '630': 'PR', '634': 'QA', '638': 'RE', '642': 'RO', '643': 'RU', '646': 'RW', '652': 'BL', '654': 'SH', '659': 'KN', '660': 'AI',
    '662': 'LC', '663': 'MF', '666': 'PM', '670': 'VC', '674': 'SM', '678': 'ST', '682': 'SA', '686': 'SN', '688': 'RS', '690': 'SC',
    '694': 'SL', '702': 'SG', '703': 'SK', '704': 'VN', '705': 'SI', '706': 'SO', '710': 'ZA', '716': 'ZW', '724': 'ES', '728': 'SS',
    '729': 'SD', '732': 'EH', '740': 'SR', '744': 'SJ', '748': 'SZ', '752': 'SE', '756': 'CH', '760': 'SY', '762': 'TJ', '764': 'TH',
    '768': 'TG', '772': 'TK', '776': 'TO', '780': 'TT', '784': 'AE', '788': 'TN', '792': 'TR', '795': 'TM', '796': 'TC', '798': 'TV',
    '800': 'UG', '804': 'UA', '807': 'MK', '818': 'EG', '826': 'GB', '831': 'GG', '832': 'JE', '833': 'IM', '834': 'TZ', '840': 'US',
    '850': 'VI', '854': 'BF', '858': 'UY', '860': 'UZ', '862': 'VE', '876': 'WF', '882': 'WS', '887': 'YE', '894': 'ZM', '900': 'XK'
  };

const getCountryISO2 = (iso3) => {
  return iso3ToIso2[iso3] || iso3;
};

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const DataStatsSection = () => {
    const [activeSection, setActiveSection] = useState(null);
    const [posthogData, setPosthogData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const POSTHOG_API = process.env.REACT_APP_POSTHOG_API || '';
    const POSTHOG_PROJECT_ID = process.env.REACT_APP_POSTHOG_PROJECT_ID || '';

    // Define the insights you want to fetch
    const insightsToFetch = [
        { id: '2zxrvcUs', name: 'Daily Visitors', type: 'line' },
        { id: 'bC427EMm', name: 'Pageviews by User Version', type: 'bar' },
        { id: '2hJudabO', name: 'User Distribution', type: 'pie' },
        { id: 'uIL3BBC8', name: 'User Locations', type: 'worldMap' },
        // Add more insights as needed
    ];

    const fetchPosthogData = async () => {
        setIsLoading(true);
        setError(null);
        try {
            if (!POSTHOG_API || !POSTHOG_PROJECT_ID) {
                throw new Error('Posthog API key or Project ID is missing');
            }

            const fetchedInsights = {};
            for (const insight of insightsToFetch) {
                const response = await axios.get(`https://eu.posthog.com/api/projects/${POSTHOG_PROJECT_ID}/insights/?short_id=${insight.id}`, {
                    headers: {
                        'Authorization': `Bearer ${POSTHOG_API}`
                    }
                });
                fetchedInsights[insight.id] = {
                    ...insight,
                    data: response.data.results[0]
                };
            }
            setPosthogData(fetchedInsights);
        } catch (err) {
            let errorMessage = 'Failed to fetch Posthog data. ';
            if (err.response) {
                errorMessage += `Server responded with status ${err.response.status}. `;
                if (err.response.data && err.response.data.detail) {
                    errorMessage += `Details: ${err.response.data.detail}`;
                }
            } else if (err.request) {
                errorMessage += 'No response received from the server.';
            } else {
                errorMessage += err.message;
            }
            setError(errorMessage);
            console.error('Error fetching Posthog data:', err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (activeSection === 'web') {
            fetchPosthogData();
        }
    }, [activeSection]);

    const processChartData = (insight) => {
        if (!insight.data || !insight.data.result) {
            console.error('Insight data or result is missing:', insight);
            return null;
        }

        const result = insight.data.result;

        if (insight.type === 'worldMap') {
            return result;
        }

        let labels, data;

        if (insight.type === 'pie') {
            // Filter out zero values for pie charts
            const filteredData = result.filter(item => item.aggregated_value > 0);
            labels = filteredData.map(item => item.label || item.breakdown_value || '');
            data = filteredData.map(item => item.aggregated_value || 0);
        } else if (Array.isArray(result)) {
            if (result.length === 0) {
                return { labels: [], data: [] };
            }
            if (result[0] && result[0].labels) {
                labels = result[0].labels;
                data = result[0].data;
            } else {
                labels = result.map(item => item.label || item.name || '');
                data = result.map(item => item.count || item.value || 0);
            }
        } else if (typeof result === 'object') {
            if (result.days) {
                labels = result.days;
                data = result.data[0].values;
            } else {
                labels = Object.keys(result);
                data = Object.values(result);
            }
        } else {
            console.error('Unexpected result structure:', result);
            return null;
        }


        return { labels, data };
    };

    const renderChart = (insight) => {
        const processedData = processChartData(insight);
        if (!processedData) {
            console.error('Failed to process data for insight:', insight);
            return <p>Unable to display chart due to data processing error.</p>;
        }

        if (insight.type === 'worldMap') {
            return renderWorldMap(processedData);
        }

        const { labels, data } = processedData;

        if (labels.length === 0 || data.length === 0 || data.every(value => value === 0)) {
            return <p>No data available for this insight.</p>;
        }

        const chartData = {
            labels: labels,
            datasets: [{
                label: insight.name,
                data: data,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            }]
        };

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: insight.name,
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        switch (insight.type) {
            case 'line':
                return <Line data={chartData} options={options} />;
            case 'bar':
                return <Bar data={chartData} options={options} />;
            case 'pie':
                return <Pie data={chartData} options={options} />;
            default:
                return <p>Unsupported chart type: {insight.type}</p>;
        }
    };

    const renderWorldMap = (data) => {
        if (!data || data.length === 0) {
            return <p>No data available for the world map.</p>;
        }

        const countryData = data.reduce((acc, country) => {
            acc[country.breakdown_value] = country.aggregated_value;
            return acc;
        }, {});

        return (
            <div>
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const iso3 = geo.id;
                                const iso2 = getCountryISO2(iso3);
                                const value = countryData[iso2] || 0;
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={value > 0 ? "#228B22" : "#D6D6DA"}
                                        stroke="#FFFFFF"
                                        style={{
                                            default: { outline: "none" },
                                            hover: { outline: "none", fill: "#80DEEA" },
                                            pressed: { outline: "none" },
                                        }}
                                        data-tooltip-id="world-map-tooltip"
                                        data-tooltip-content={`${geo.properties.name}: ${value}`}
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
                <ReactTooltip id="world-map-tooltip" />
                <div className="country-labels">
                    <h4>Top Countries:</h4>
                    {Object.entries(countryData)
                        .sort(([, a], [, b]) => b - a)
                        .map(([country, value], index) => (
                            <div key={index}>
                                {country}: {value} users
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    const renderPosthogData = () => {
        if (isLoading) return <p>Loading Posthog data...</p>;
        if (error) return (
            <div className="error">
                <p>{error}</p>
                <p>Please check your Posthog API key and Project ID in the .env file.</p>
            </div>
        );
        if (Object.keys(posthogData).length === 0) return null;
    
        return (
            <div className="posthog-data">
                <h3 className="posthog-title">Posthog Insights</h3>
                <div className="posthog-insights-grid">
                    {Object.values(posthogData).map((insight) => (
                        <div key={insight.id} className="insight-item">
                            <h4>{insight.name}</h4>
                            {renderChart(insight)}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const debugInfo = (
        <div className="debug-info">
            <h5>Debug Info</h5>
            <p>API: {POSTHOG_API ? '✓' : '✗'} | Project ID: {POSTHOG_PROJECT_ID || 'N/A'}</p>
            <button onClick={fetchPosthogData}>Retry Fetch</button>
        </div>
    );

    return (
        <div className="data-stats-section">
            <div className="data-stats-buttons">
                <button 
                    className={activeSection === 'main' ? 'active' : ''}
                    onClick={() => setActiveSection('main')}
                    disabled
                >
                    Main Stats
                </button>
                <button 
                    className={activeSection === 'web' ? 'active' : ''}
                    onClick={() => setActiveSection('web')}
                >
                    Web Analytics
                </button>
                <button 
                    className={activeSection === 'mobile' ? 'active' : ''}
                    onClick={() => setActiveSection('mobile')}
                    disabled
                >
                    Mobile Analytics
                </button>
            </div>
            {activeSection && (
                <div className="data-stats-content">
                    {activeSection === 'main' && <div>Main Stats Content</div>}
                    {activeSection === 'web' && (
                        <>
                            {debugInfo}
                            {renderPosthogData()}
                        </>
                    )}
                    {activeSection === 'mobile' && <div>Mobile Analytics Content</div>}
                </div>
            )}
        </div>
    );
};

export default DataStatsSection;