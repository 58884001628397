import React, { useState, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const ItemTypes = {
  ITEM: 'item',
};

function DraggableItem({ id, text, index, moveItem }) {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref} style={{ opacity, cursor: 'move', padding: '16px', margin: '0 0 8px 0', background: 'white', border: '1px dashed gray' }}>
      {text}
    </div>
  );
}

function Container({ initialItems, onReorder }) {
  const [items, setItems] = useState(initialItems);

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const newItems = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, items[dragIndex]],
      ],
    });
    setItems(newItems);
    onReorder(newItems);  // Notify the parent about the reorder
  }, [items, onReorder]);

  return (
    <div>
      {items.map((item, index) => (
        <DraggableItem key={item.id} id={item.id} text={`${index + 1}. ${item.name} ${item.surname}`} index={index} moveItem={moveItem} />
      ))}
    </div>
  );
}

export default function DragDropComponent({ people, onReorder }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container initialItems={people} onReorder={onReorder} />
    </DndProvider>
  );
}
