// src/control/Files.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Files.css';
import { Pie } from 'react-chartjs-2';

import AddFileModal from './components/AddFileModal';
import Sidebar from './components/Sidebar';
import { FaTimes, FaSave, FaPlus, FaFileAlt, FaFileUpload, FaTimesCircle, FaTrash, FaEdit, FaRobot } from 'react-icons/fa';

import categoriesOfExpertise from './config/expertiseCategories';
import { getFilesPieChartData, filesPieChartOptions } from './config/filesPieChartConfig';

import placeholderImage from '../assets/Icon_App.png';

import { useLocation } from 'react-router-dom';

import axios from './helpers/axiosWithAuth';

const Files = ({ isFilesQueued, setIsFilesQueued }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const initialFetchRef = useRef(true);
  const initialSelectedPersonRef = useRef(true);
  const initialSelectedPersonId = query.get('selectedPersonId') || '';
  const [selectedPersonId, setSelectedPersonId] = useState(initialSelectedPersonId);
  const [selectedPersonData, setSelectedPersonData] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [audio, setAudio] = useState(null);
  const [document, setDocument] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [transcription, setTranscription] = useState('');
  const [fileSourceType, setFileSourceType] = useState('Podcast');
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [addFileName, setAddFileName] = useState('');
  const [isDocumentSelected, setIsDocumentSelected] = useState(false);
  const [isAudioFileSelected, setIsAudioFileSelected] = useState(false);
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [isEditFileModalOpen, setIsEditFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [isCategoriesEditable, setIsCategoriesEditable] = useState(false);
  const [editableFilename, setEditableFilename] = useState('');

  const [dataForCategoryChart, setDataForCategoryChart] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }]
  });
  
  const [dataForItemChart, setDataForItemChart] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }]
  });

  const [peopleSearchInput, setPeopleSearchInput] = useState('');
  const [people, setPeople] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [isLoadingPeople, setIsLoadingPeople] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('ID');

  const sortOptions = ['ID', 'Alphabetical'];
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [initialSelectedExpertise, setInitialSelectedExpertise] = useState([]);
  const categories_of_expertise = categoriesOfExpertise;  
  const [tempSelectedExpertise, setTempSelectedExpertise] = useState([...categories_of_expertise.flatMap(category => category.items)]);

  const [fileSearchInput, setFileSearchInput] = useState('');
  const [fileFilter, setFileFilter] = useState('All');
  const [originalFilesList, setOriginalFilesList] = useState([]);
  const filterMapping = {
    "All": "",
    "Podcasts": "podcast",
    "Books": "book",
    "Research Papers": "research"
  };

  const [personFileUpdateStatus, setPersonFileUpdateStatus] = useState({});

  const [fileDetails, setFileDetails] = useState({
    people_ids: [],
    file_type: '',
    file_has_categories: false,
    categories_of_expertise: [],
    categories_of_expertise_available: [],
    categories_of_expertise_gpt: []
  });
  const [isCategoriesVisible, setIsCategoriesVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const getCategoryTooltip = (buttonClass) => {
    switch (buttonClass) {
      case 'expertise-green':
        return 'Agrees with current selection';
      case 'expertise-orange':
        return 'Disagrees with current selection';
      case 'expertise-yellow':
        return 'GPT thinks this category should be included';
      case 'expertise-red':
        return 'Not part of available categories but GPT recommended';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      fetchPeopleData();
    }
  }, []);

  useEffect(() => {
    if (initialSelectedPersonRef.current && initialSelectedPersonId && people.length > 0) {
      selectPersonFromSearch(parseInt(initialSelectedPersonId));
      initialSelectedPersonRef.current = false;
    }
  }, [people]);
  
  
  const handleToggleCategories = () => {
    setIsCategoriesVisible(!isCategoriesVisible);
    if (!isCategoriesVisible) {
      const initialCategories = fileDetails.categories_of_expertise.flatMap(cat => cat.items);
      setSelectedCategories(initialCategories);
    } else {
      setIsCategoriesEditable(false);
    }
  };

  const handleCategoryClick = (categoryItem) => {
    if (selectedCategories.includes(categoryItem)) {
      setSelectedCategories(selectedCategories.filter(item => item !== categoryItem));
    } else {
      setSelectedCategories([...selectedCategories, categoryItem]);
    }
  };

  // Update chart data whenever the selected person data changes
  useEffect(() => {
    if (selectedPersonData && selectedPersonData.person_categories_character_count) {
        const { dataForCategoryChart, dataForItemChart } = getFilesPieChartData(selectedPersonData.person_categories_character_count);
        
        // Include person data in the datasets
        dataForCategoryChart.datasets[0].personData = selectedPersonData.person_categories_character_count.map(cat => ({
            name: selectedPersonData.name,
            surname: selectedPersonData.surname,
            id: selectedPersonData.id,
        }));
        dataForItemChart.datasets[0].personData = selectedPersonData.person_categories_character_count.flatMap(cat =>
            cat.items.map(item => ({
                name: selectedPersonData.name,
                surname: selectedPersonData.surname,
                id: selectedPersonData.id,
            }))
        );

        setDataForCategoryChart(dataForCategoryChart);
        setDataForItemChart(dataForItemChart);
    } else {
        setDataForCategoryChart({
            labels: [],
            datasets: [{ data: [], backgroundColor: [] }]
        });
        setDataForItemChart({
            labels: [],
            datasets: [{ data: [], backgroundColor: [] }]
        });
    }
  }, [selectedPersonData]);
  
  

  useEffect(() => {
    if (isEditFileModalOpen) {
      const initialCategories = fileDetails.categories_of_expertise.flatMap(cat => cat.items);
      setSelectedCategories(initialCategories);
    }
  }, [isEditFileModalOpen, fileDetails.categories_of_expertise]);

  const handleSaveCategories = async () => {
    // Transform selectedCategories to match the expected JSON structure
    const transformedCategories = categoriesOfExpertise.map(category => {
      return {
        id: category.id,
        category: category.category,
        items: category.items.filter(item => selectedCategories.includes(item))
      };
    }).filter(category => category.items.length > 0);
    try {
      const response = await axios.post(`${API_BASE_URL}/save-categories`, {
        file_name: selectedFile.name,
        categories: transformedCategories,
      });
      if (response.status === 200) {
        alert('Categories saved successfully');
        setFileDetails(prevDetails => ({
          ...prevDetails,
          categories_of_expertise: transformedCategories,
          //file_has_categories: true,
          file_has_categories: transformedCategories.length > 0,
        }));
        setIsCategoriesVisible(false);
        fetchFilesForPerson(selectedPersonId);
        fetchPersonFilesUpdateStatus();
        setIsCategoriesEditable(false);

      } else {
        alert('Failed to save categories');
      }
    } catch (error) {
      console.error('Error saving categories:', error);
      alert('There was an error saving the categories.');
    }
  };
  
  const handleAskGPT = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/ask-gpt`, {
        file_name: selectedFile.name,
        file_content: fileContent,
      });
      if (response.status === 200) {
        alert('GPT recommendation saved successfully');
        const categories = Array.isArray(response.data.categories) ? response.data.categories : [];
        setFileDetails(prevDetails => ({
          ...prevDetails,
          categories_of_expertise_gpt: categories,
        }));
        fetchFilesForPerson(selectedPersonId);
        fetchPersonFilesUpdateStatus();
      } else {
        alert('Failed to get GPT recommendation');
      }
    } catch (error) {
      console.error('Error getting GPT recommendation:', error);
      alert('There was an error getting the GPT recommendation.');
    }
  };  


  const handleCloseEditFileModal = () => {
    setIsEditFileModalOpen(false);
    setIsCategoriesVisible(false);
    setSelectedCategories([]);
  };


  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setCurrentFilter(selectedFilter);
    
    let sortedList = [];
    if (selectedFilter === 'Alphabetical') {
      sortedList = [...peopleList].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedList = [...peopleList].sort((a, b) => a.id - b.id);
    }
    setPeopleList(sortedList);
  };

  const handleFileFilterChange = (e) => {
    const selectedFilter = e.target.value;
    const searchTerm = filterMapping[selectedFilter.toLowerCase()] || "";
    setFileFilter(selectedFilter);
  };
  

  const fetchPeopleData = useCallback(async () => {
    setIsLoadingPeople(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/get-people`);
      if (response.data) {
        const updatedPeopleData = response.data.map(person => ({
          ...person,
          image_url: person.has_image ? `${person.image_url}?v=${Date.now()}` : placeholderImage
        }));
        setPeople(updatedPeopleData);
        setPeopleList(updatedPeopleData);  // Set peopleList without sorting
        setIsLoadingPeople(false);
      }
    } catch (error) {
      console.error("Failed to fetch people:", error);
      setIsLoadingPeople(false);
    }
  }, [API_BASE_URL]);  

  useEffect(() => {
      fetchPeopleData();
  }, [fetchPeopleData]);;

  const selectPersonFromSearch = async (id) => {
    setSelectedPersonId(id);
    setPeopleSearchInput('');
    resetFileFilters();
  
    // Fetch selected person data from API
    const personResponse = await axios.get(`${API_BASE_URL}/get-person/${id}`);
    const personDataFromApi = personResponse.data;
  
    // Fetch selected person data from people array
    const person = people.find(person => person.id === id);
  
    // Merge data from API and local data
    const mergedPersonData = { ...person, ...personDataFromApi };
  
    setSelectedPersonData(mergedPersonData);
  
    await fetchFilesForPerson(id);
  };

  const toggleFilterModal = () => {
    if (!isFilterModalOpen) {
      setInitialSelectedExpertise([...tempSelectedExpertise]);
    }
    setTempSelectedExpertise([...tempSelectedExpertise]);
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleSelectAll = () => {
    setTempSelectedExpertise(categories_of_expertise.flatMap(category => category.items));
  };

  const handleDeselectAll = () => {
    setTempSelectedExpertise([]);
  };

  const handleTempExpertiseChange = (expertise) => {
    if (tempSelectedExpertise.includes(expertise)) {
      setTempSelectedExpertise(tempSelectedExpertise.filter(item => item !== expertise));
    } else {
      setTempSelectedExpertise([...tempSelectedExpertise, expertise]);
    }
  };

  const handleCancelFilters = () => {
    setTempSelectedExpertise([...initialSelectedExpertise]);
    setIsFilterModalOpen(false);
  };

  const handleApplyFilters = () => {
    setTempSelectedExpertise(tempSelectedExpertise);
    setIsFilterModalOpen(false);
    if (tempSelectedExpertise.length === 0) {
        setPeopleList([]);
    } else {
        setPeopleList(people.filter(person => tempSelectedExpertise.some(expertise => person.categories_of_expertise.includes(expertise))));
    }
  };

  const getFilterButtonText = () => {
    if (tempSelectedExpertise.length === categories_of_expertise.flatMap(category => category.items).length) {
        return 'Filter (all)';
    } else if (tempSelectedExpertise.length === 0) {
        return 'Filter (none)';
    } else {
        return 'Filter (custom)';
    }
  };

  useEffect(() => {
    const filterPeople = () => {
      if (tempSelectedExpertise.length === 0) {
        setPeopleList(people);
      } else {
        const filtered = people.filter(person =>
            person.categories_of_expertise.some(expertise => tempSelectedExpertise.includes(expertise))
        );
        setPeopleList(filtered);
      }
    };

    filterPeople();
  }, [tempSelectedExpertise, people]);

  const handlePeopleSearchChange = (e) => {
    const input = e.target.value.toLowerCase();
    setPeopleSearchInput(input);
    
    if (input.trim() !== '') {
        const filtered = people.filter(person =>
            `${person.name} ${person.surname}`.toLowerCase().includes(input)
        );
        setPeopleList(filtered);
    } else {
        setPeopleList(people);
    }
  };

  const extractUserFileName = (fileName) => {
    const parts = fileName.split('-');
    const userFileName = parts.slice(2, -1).join(' ').replace(/_/g, ' ');
    return userFileName;
  };

  const handleFileSearchChange = (e) => {
    const input = e.target.value.toLowerCase();
    setFileSearchInput(input);

    if (input.trim() !== '') {
      const filtered = originalFilesList.filter(file =>
        file.name.toLowerCase().includes(input.replace(/\s+/g, '_')) || // Handle spaces in the input
        extractUserFileName(file.name).toLowerCase().includes(input.replace(/\s+/g, '_')) // Handle spaces in the user-given filenames
      );
      setFilesList(filtered);
    } else {
      setFilesList(originalFilesList); // Use originalFilesList to reset
    }
  };


  useEffect(() => {
    let filteredFiles = originalFilesList;

    if (fileFilter !== 'All') {
        filteredFiles = filteredFiles.filter(file => file.name.toLowerCase().includes(filterMapping[fileFilter]));
    }

    if (fileSearchInput.trim() !== '') {
        filteredFiles = filteredFiles.filter(file =>
            file.name.toLowerCase().includes(fileSearchInput.replace(/\s+/g, '_')) || 
            extractUserFileName(file.name).toLowerCase().includes(fileSearchInput.replace(/\s+/g, '_'))
        );
    }

    setFilesList(filteredFiles);
  }, [fileFilter, fileSearchInput, originalFilesList]);


  const resetFileFilters = () => {
    setFileFilter('All');
    setFileSearchInput('');
  };


  

  const renderPerson = (person) => {
    return (
      <div key={person.id} className="person-container" onClick={() => selectPersonFromSearch(person.id)}>
        <div>
          <img
            src={person.image_url}
            alt={`${person.name} ${person.surname}`}
            className="person-image"
          />
          <div className="person-details">
            <h4 className="person-id">{person.id}</h4>
            <h4 className="person-name">{person.name}</h4>
            <h4 className="person-surname">{person.surname.split(' ')[0]}</h4>
            <p className="person-subtitle">{person.short_bio}</p>
          </div>
        </div>
      </div>
    );
  };

  const fetchUpdatedPersonData = async (personId) => {
    try {
      const personResponse = await axios.get(`${API_BASE_URL}/get-person/${personId}`);
      const personDataFromApi = personResponse.data;
  
      // Fetch selected person data from people array
      const person = people.find(person => person.id === personId);
  
      // Merge data from API and local data
      const mergedPersonData = { ...person, ...personDataFromApi };
  
      setSelectedPersonData(mergedPersonData);
    } catch (error) {
      console.error("Failed to fetch updated person data:", error);
    }
  };
  
  const fetchFilesForPerson = async (personId) => {
    if (personId) {
      setIsLoadingFiles(true);
      try {
        const filesResponse = await axios.get(`${API_BASE_URL}/get-person-files/${personId}`);
        const files = filesResponse.data.files.map(file => ({
          ...file,
          categories_of_expertise_gpt: file.categories_of_expertise_gpt || [],
          is_categories_empty: !file.file_has_categories
        }));
        setFilesList(files);
        setOriginalFilesList(files);
      } catch (error) {
        console.error("There was an error fetching the files: ", error);
        setFilesList([]);
      } finally {
        setIsLoadingFiles(false);
      }
    } else {
      setFilesList([]);
    }
  };
  

  const getFileBorderColor = (file) => {
    if (!file.in_db) {
      return '2px solid red';
    } else if (!file.file_has_categories) {
      return '2px solid orange';
    } else if (file.is_categories_gpt_empty) {
      return '2px solid yellow';
    } else {
      return 'none';
    }
  };
  
  const getFileTooltip = (file) => {
    if (!file.in_db) {
      return 'File not in database';
    } else if (!file.file_has_categories) {
      return 'File has no categories';
    } else if (file.is_categories_gpt_empty) {
      return 'Categories have not yet been generated by GPT';
    } else {
      return '';
    }
  };

  const handleEditFile = (file) => {
    setSelectedFile(file);
    setIsEditable(false);
    const editablePart = extractEditablePartFromPersonFilename(file.name);
    setEditableFilename(editablePart);

    if (people.length === 0) {
      fetchPeopleData().then(() => {
        openEditFileModal(file);
      });
    } else {
      openEditFileModal(file);
    }
  };

  const openEditFileModal = (file) => {
    axios.get(`${API_BASE_URL}/get-file-info/${encodeURIComponent(file.name)}`)
      .then(response => {
        const fileData = response.data;
  
        setFileContent(fileData.content);
        setFileDetails(prevDetails => ({
          ...prevDetails,
          people_ids: fileData.people_ids,
          file_type: fileData.file_type,
          file_has_categories: fileData.file_has_categories,
          categories_of_expertise: fileData.categories_of_expertise,
          categories_of_expertise_available: fileData.categories_of_expertise_available,
          categories_of_expertise_gpt: fileData.categories_of_expertise_gpt,
          character_count: fileData.character_count !== null ? fileData.character_count : "Null"
        }));
        setIsEditFileModalOpen(true);
      })
      .catch(error => console.error("Error fetching file content and details:", error));
  };

  const extractEditablePartFromPersonFilename = (filename) => {
    const match = filename.match(/[^\d-]+-(.+)\.txt$/);
    return match ? match[1].replace(/_/g, ' ') : '';
  };

  const reconstructPersonFilename = (originalName, editedNamePart) => {
    const prefixMatch = originalName.match(/^(\d+-)+[^\d-]+-/);
    const prefix = prefixMatch ? prefixMatch[0] : '';
    const safeEditedNamePart = editedNamePart.trim().replace(/\s+/g, '_');
    return `${prefix}${safeEditedNamePart}.txt`;
  };

  const handleSaveFile = async () => {
    if (!selectedFile || !editableFilename) {
      alert('No file selected');
      return;
    }

    // Reconstruct the filename
    const fullFilename = reconstructPersonFilename(selectedFile.name, editableFilename);
    
    // Calculate character count excluding the first two lines
    const fileContentLines = fileContent.split('\n');
    const characterCount = fileContentLines.slice(2).join('\n').length;

    try {
      const response = await axios.post(`${API_BASE_URL}/save-file-content`, {
        originalFileName: selectedFile.name,
        newFileName: fullFilename,
        content: fileContent,
        characterCount: characterCount,
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.status === 200) {
        alert('File saved successfully');
        updateFilesListWithNewName(selectedFile.name, fullFilename);
        setIsEditable(false);
        handleCloseEditFileModal();
        setEditableFilename('');
        fetchFilesForPerson(selectedPersonId);
        fetchPersonFilesUpdateStatus();
      } else {
        alert('Failed to save the file');
      }
    } catch (error) {
      console.error('Error saving the file:', error);
      alert('There was an error saving the file.');
    }
  };

  const updateFilesListWithNewName = (originalName, newName) => {
    setFilesList(currentFilesList =>
      currentFilesList.map(file =>
        file.name === originalName ? { ...file, name: newName } : file
      )
    );
  };

  const handleDeleteFile = async (file) => {
    if (!file || !selectedPersonId) return;
    const isConfirmed = window.confirm("Are you sure you want to delete this file permanently?");
    if (isConfirmed) {
      try {
        const response = await axios.post(`${API_BASE_URL}/delete-person-file`, {
          file_name: file.name,
        });
        if (response.status === 200) {
          alert("File deleted successfully!");
          handleCloseEditFileModal();
          setSelectedFile(null);
          fetchFilesForPerson(selectedPersonId);
          fetchPersonFilesUpdateStatus();
        } else {
          alert("Failed to delete the file.");
        }
      } catch (error) {
        console.error("Error deleting the file:", error);
        alert("There was an error deleting the file.");
      }
    }
  };

  const resetFileForm = () => {
    setYoutubeUrl('');
    setAudio(null);
    setUploadStatus('');
    setTranscription('');
    setFileSourceType('Podcast');
    setSelectedPeople([selectedPersonId]);
    setAddFileName('');
    setDescription('');
    setKeywords('');
    setIsDocumentSelected(false);
    setIsAudioFileSelected(false);
  };

  const fetchPersonFilesUpdateStatus = useCallback(async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-person-files-update-status`);
        if (response.data) {
            setPersonFileUpdateStatus(response.data);
        }
    } catch (error) {
        console.error('Failed to fetch person files update status:', error);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
      fetchPersonFilesUpdateStatus();
  }, [fetchPersonFilesUpdateStatus]);


  const handleGenerateAssistantFile = async () => {
    if (!selectedPersonId) {
      alert('Please select a person to generate the assistant file.');
      return;
    }

    if (filesList.length === 0) {
      alert('No files available for the selected person.');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/generate-assistant-file`, {
        person_ids: [selectedPersonId]
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.status === 200) {
        alert('Assistant file generated successfully');
        fetchPersonFilesUpdateStatus();
        await fetchUpdatedPersonData(selectedPersonId);
      } else {
        alert('Failed to generate the assistant file');
      }
    } catch (error) {
      console.error('Error generating the assistant file:', error);
      alert('There was an error generating the assistant file.');
    }
  };

  const getIconAttributes = (status, filesListLength) => {
    let color, hoverColor;
    if (filesListLength === 0) {
      color = 'red';
    } else if (status) {
      color = 'green';
    } else {
      color = 'yellow';
      if (filesListLength > 0 && !status) {
        hoverColor = '#80DEEA';
      }
    }
  
    const onClick = (status || filesListLength === 0) ? null : handleGenerateAssistantFile;
    const cursor = (status || filesListLength === 0) ? 'default' : 'pointer';
    
    return { color, onClick, cursor, hoverColor };
  };
  const selectedPersonStatus = personFileUpdateStatus[selectedPersonId];
  const { color: iconColor, onClick: iconOnClick, cursor: iconCursor, hoverColor } = getIconAttributes(selectedPersonStatus, filesList.length);


  const renderCategoryItem = (item) => {
    const isSelected = selectedCategories.includes(item);
    const isAvailable = fileDetails.categories_of_expertise_available.some(availCategory => 
      availCategory.items.includes(item)
    );
    const isGptRecommended = Array.isArray(fileDetails.categories_of_expertise_gpt) && fileDetails.categories_of_expertise_gpt.some(gptCategory =>
      gptCategory.items.includes(item)
    );
  
    let buttonClass = '';
  
    if (isSelected && isGptRecommended) {
      buttonClass = 'expertise-green'; // Both selected and GPT recommended
    } else if (isSelected && !isGptRecommended) {
      buttonClass = 'expertise-orange'; // Selected but not GPT recommended
    } else if (!isSelected && isGptRecommended) {
      if (!isAvailable) {
        buttonClass = 'expertise-red'; // GPT recommended but not available
      } else {
        buttonClass = 'expertise-yellow'; // Not selected but GPT recommended
      }
    } else if (isAvailable) {
      buttonClass = 'expertise-available'; // Available but not selected
    } else {
      buttonClass = 'expertise-not-available'; // Not available and not selected
    }
  
    return (
      <button
        key={item}
        type="button"
        className={`${buttonClass} ${!isCategoriesEditable ? 'unclickable' : ''}`}
        disabled={!isAvailable && !isSelected}
        onClick={isCategoriesEditable ? () => handleCategoryClick(item) : null}
        title={getCategoryTooltip(buttonClass)}
      >
        {item}
      </button>
    );
  };
   
  

  return (
    <div className="files">
      <Sidebar 
        isFilesQueued={isFilesQueued} //needed??
        setIsFilesQueued={setIsFilesQueued} //needed??
        isSidebarOpen={isSidebarOpen} //needed??
        setIsSidebarOpen={setIsSidebarOpen}
        selectedPersonId={selectedPersonId}
        fetchFilesForPerson={fetchFilesForPerson}
        fetchPersonFilesUpdateStatus={fetchPersonFilesUpdateStatus}
      />
      <div className={`files-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <h1 className='page-title'>Manage Files</h1>

        <div className="configuration-selectors">
          <select className="dropdown-selector" onChange={handleFilterChange} value={currentFilter}>
            {sortOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
          <input
            type="search"
            placeholder="Search by name or surname..."
            className="search-input"
            value={peopleSearchInput}
            onChange={handlePeopleSearchChange}
          />
          <button className="dropdown-button" onClick={toggleFilterModal}>
            {getFilterButtonText()}
          </button>
          {isFilterModalOpen && (
            <div className="expertise-modal">
              <div className="expertise-modal-content">
                <div className="config-icons">
                  <FaTimes title="Cancel" className='config-icon' onClick={handleCancelFilters} />
                  <FaSave title="Apply" className='config-icon' onClick={handleApplyFilters} />
                </div>
                <h3>Filter by Expertise</h3>
                <div className="modal-buttons">
                  <button className="wide-button" onClick={handleSelectAll}>Select All</button>
                  <button className="wide-button" onClick={handleDeselectAll}>Deselect All</button>
                </div>
                <div className="expertise-categories-container">
                  {categories_of_expertise.map(category => (
                    <div key={category.id} className="expertise-category">
                      <strong>{category.category}</strong>
                      <div>
                        {category.items.map(item => (
                          <button
                            key={item}
                            type="button"
                            className={tempSelectedExpertise.includes(item) ? "expertise-selected" : "expertise-not-selected"}
                            onClick={() => handleTempExpertiseChange(item)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
          </div>
        )}
        </div>

        <div className="configuration-section">
          <div className="people-slider">
            {isLoadingPeople ? (
              <div className="no-files-message">Loading people...</div>
            ) : (
              peopleList.map(person => renderPerson(person))
            )}
          </div>
        </div>

        {selectedPersonId && selectedPersonData && (
          <div className="person-files-form">
            <div className="config-icons">
              <FaFileUpload
                title="Generate Assistant File"
                className={`config-icon ${hoverColor ? 'hover-effect' : ''}`}
                onClick={iconOnClick}
                style={{ color: iconColor, cursor: iconCursor }}
              />
              <FaTimes title="Close" className='config-icon' onClick={() => setSelectedPersonId('')} />
            </div>
            <div className="person-files-row">
              <div className="person-image-container">
                <img
                  src={selectedPersonData.image_url}
                  alt={`${selectedPersonData.name} ${selectedPersonData.surname}`}
                  className="person-files-image"
                />
              </div>
              <div className="personfiles-input-column">
                <div>{selectedPersonData.name}</div>
                <div>{selectedPersonData.surname}</div>
              </div>
            </div>
            <div className="pie-chart-container">
              {selectedPersonData.person_categories_character_count && (
                <div className="pie-charts">
                  <div className="pie-chart">
                    <h3>Characters by Category</h3>
                    <Pie data={dataForCategoryChart} options={filesPieChartOptions} />
                  </div>
                  <div className="pie-chart">
                    <h3>Characters by Item</h3>
                    <Pie data={dataForItemChart} options={filesPieChartOptions} />
                  </div>
                </div>
              )}
            </div>
            {!isLoadingFiles && originalFilesList.length > 0 && (
              <div className="files-grid-controls">
                <select
                  className="dropdown-selector"
                  onChange={(e) => handleFileFilterChange(e)}
                  value={fileFilter}
                >
                  <option value="All">All</option>
                  <option value="Podcasts">Podcasts</option>
                  <option value="Books">Books</option>
                  <option value="Research Papers">Research Papers</option>
                </select>
                <input
                  type="search"
                  placeholder="Search files..."
                  className="search-input"
                  value={fileSearchInput}
                  onChange={handleFileSearchChange}
                />
              </div>
            )}
            <div>
              {isLoadingFiles ? (
                <div className="no-files-message">Loading files...</div>
              ) : (
                <>
                  {filesList.length > 0 ? (
                    <>
                      <div className="files-grid">
                        <div className="add-file-container" onClick={() => setIsFileModalOpen(true)}>
                          <FaPlus size={32} />
                        </div>
                        {filesList.map((file, index) => (
                          <div
                            key={index}
                            className="file-container"
                            onClick={() => handleEditFile(file)}
                            style={{
                              border: getFileBorderColor(file),
                            }}
                            title={getFileTooltip(file)}
                          >
                            <div className="person-file-icon">
                              <FaFileAlt />
                            </div>
                            <div className="file-details">
                              <h4 className="file-name">{file.name}</h4>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="no-files-container">
                      <div className="no-files-message">No files available for this person</div>
                      <div className="add-file-container" onClick={() => setIsFileModalOpen(true)}>
                        <FaPlus size={32} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {isEditFileModalOpen && (
          <div className="view-editfile-modal">
            <div className="view-editfile-modal-content">
              <div className="config-icons">
                {isEditable ? (
                    <>
                    <FaTimesCircle title="Cancel" className='config-icon' onClick={() => setIsEditable(false)} />
                    <FaSave title="Save" className='config-icon' onClick={handleSaveFile} />
                    <FaTrash title="Delete" className='config-icon' onClick={() => handleDeleteFile(selectedFile)} />
                    </>
                ) : (
                    <>
                    <FaEdit title="Edit" className='config-icon' onClick={() => setIsEditable(true)} />
                    </>
                )}
                <FaTimes title="Close" className='config-icon' onClick={handleCloseEditFileModal} />
              </div>
              {isEditable ? (
                <input
                  type="text"
                  className="files-title"
                  value={editableFilename}
                  onChange={(e) => setEditableFilename(e.target.value)}
                />
              ) : (
                <h3 className="files-title">{selectedFile && selectedFile.name}</h3>
              )}
              <div className="file-details">
                <div className="file-type-container">
                  <h4 className="file-type-title">{fileDetails.file_type}</h4>
                  <p className="file-character-count">Character Count: {fileDetails.character_count}</p>
                  <div className="file-people">
                    {fileDetails.people_ids.map((id) => {
                      const person = people.find((p) => p.id == id);
                      return person ? (
                        <div key={id} className="file-person">
                          <img
                            src={person.image_url}
                            alt={person.name}
                            className="file-person-image"
                          />
                          <div className="file-person-name">{person.name} {person.surname}</div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
                
                {fileDetails.categories_of_expertise_gpt.length === 0 && (
                  <p className="info-message">! GPT recommendations have not yet been generated for this file !</p>
                )}
                {fileDetails.categories_of_expertise.length === 0 && (
                  <p className="info-message">! This file has not yet been assigned any categories !</p>
                )}
                <button className="categories-button" onClick={handleToggleCategories}>
                  {isCategoriesVisible ? "Close" : "Categories"}
                </button>

                {isCategoriesVisible && (
                  <div className="file-categories-section">
                    <div className="category-buttons">
                      {!isCategoriesEditable && (
                        <FaEdit
                          title="Edit"
                          className="config-icon"
                          onClick={() => setIsCategoriesEditable(true)}
                        />
                      )}
                      {isCategoriesEditable && (
                        <>
                          <FaTimesCircle
                            title="Cancel"
                            className="config-icon"
                            onClick={() => setIsCategoriesEditable(false)}
                          />
                          <FaSave
                            title="Save"
                            className={`config-icon ${!isCategoriesEditable ? 'unclickable' : ''}`}
                            onClick={isCategoriesEditable ? handleSaveCategories : null}
                          />
                        </>
                      )}
                      <FaRobot
                        title="Ask GPT"
                        className={`config-icon ${!isCategoriesEditable ? 'unclickable' : ''}`}
                        onClick={isCategoriesEditable ? handleAskGPT : null}
                      />
                    </div>
                    <div className="status-bar">
                      <div className="status-item green">
                        <div className="circle"></div>
                        Agrees
                      </div>
                      <div className="status-item orange">
                        <div className="circle"></div>
                        Disagrees
                      </div>
                      <div className="status-item yellow">
                        <div className="circle"></div>
                        GPT Included
                      </div>
                      <div className="status-item red">
                        <div className="circle"></div>
                        GPT Recommended
                      </div>
                    </div>
                    {categoriesOfExpertise.map(category => (
                      <div key={category.id}>
                        <strong>{category.category}</strong>
                        <div>
                          {category.items.map(renderCategoryItem)}
                        </div>
                      </div>
                    ))}
                  </div>
                  )}
              </div>
                
              <h4 className="file-type-title">File Content:</h4>
              <textarea
                value={fileContent}
                onChange={(e) => setFileContent(e.target.value)}
                readOnly={!isEditable}
                style={{
                  width: '100%',
                  height: '350px',
                  backgroundColor: isEditable ? 'white' : '#e0e0e0',
                  borderColor: isEditable ? '#121212' : '#80DEEA',
                  borderRadius: '5px',
                  resize: 'none',
                }}
              />
            </div>
          </div>
        )}

        <AddFileModal
          isOpen={isFileModalOpen}
          onClose={() => setIsFileModalOpen(false)}
          youtubeUrl={youtubeUrl}
          setYoutubeUrl={setYoutubeUrl}
          audio={audio}
          setAudio={setAudio}
          document={document}
          setDocument={setDocument}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          transcription={transcription}
          setTranscription={setTranscription}
          fileSourceType={fileSourceType}
          setFileSourceType={setFileSourceType}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
          addFileName={addFileName}
          setAddFileName={setAddFileName}
          selectedPersonId={selectedPersonId}
          isDocumentSelected={isDocumentSelected}
          setIsDocumentSelected={setIsDocumentSelected}
          isAudioFileSelected={isAudioFileSelected}
          setIsAudioFileSelected={setIsAudioFileSelected}
          description={description}
          setDescription={setDescription}
          keywords={keywords}
          setKeywords={setKeywords}
          setIsFilesQueued={setIsFilesQueued}
          resetFileForm={resetFileForm} 
          onEditTranscriptionFile={(file) => {
            setIsFileModalOpen(false);
            handleEditFile(file);
            fetchFilesForPerson();
          }}
          peopleList={peopleList}
          fetchFilesForPerson={fetchFilesForPerson}  // Pass the function as a prop
          fetchPersonFilesUpdateStatus={fetchPersonFilesUpdateStatus}  // Pass the function as a prop
        />
      </div>
    </div>
  );
};

export default Files;

