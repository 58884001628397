// LoginModal.js
import React, { useState } from 'react';
import './LoginModal.css';
import axios from 'axios';

const LoginModal = ({ isOpen, onClose, onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitting login to API_BASE_URL: ', API_BASE_URL);
    try {
      const response = await axios.post(`${API_BASE_URL}/admin_login`, { username, password });
      if (response.status === 200 && response.data.access_token) {
        // Store the JWT token in localStorage
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('admin_id', response.data.admin_id);
        onLogin(true);
      }
    } catch (error) {
      alert('Incorrect credentials!');
    }
  };

  // Initialize a state to track whether the click started inside the modal content
  const [isClickInside, setIsClickInside] = useState(false);

  const handleBackdropClick = (e) => {
    // Only close the modal if the click did not start inside (isClickInside is false)
    if (!isClickInside) {
      onClose();
      setUsername('');
      setPassword('');
    }
    setIsClickInside(false);
  };

  const handleModalMouseDown = () => {
    // When the mouse is pressed down inside the modal content, set isClickInside to true
    setIsClickInside(true);
  };

  if (!isOpen) return null;

  return (
    <div className="login-modal" onClick={handleBackdropClick}>
       <div className="login-content" onMouseDown={handleModalMouseDown} onClick={(e) => e.stopPropagation()}>
        <h2>Control Panel Login</h2>
        <p>Admin access only</p>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className="login-modal-buttons">
            <button type="submit">Submit</button>
            <button onClick={onClose}>Close</button>
          </div>
        </form>
    </div>
    </div>
  );
};

export default LoginModal;
