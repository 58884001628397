// src/control/config/changeLogChartConfig.js
import { Chart } from 'chart.js';

import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const createOnlineActivityChart = (ctx, data) => {
  const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  const labels = Array.from({ length: daysInMonth }, (_, i) => new Date(new Date().getFullYear(), new Date().getMonth(), i + 1).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));

  const datasets = [1, 2].map(admin_id => {
    const adminData = data.filter(log => log.admin_id === admin_id);

    return {
      label: `Admin ${admin_id}`,
      data: labels.map((_, index) => {
        const logs = adminData.filter(log => new Date(log.date_changed).getDate() === index + 1);
        return logs.length;
      }),
      backgroundColor: admin_id === 1 ? '#39FF14' : '#228B22',  // Light neon green for admin_id=1 and darker green for admin_id=2
      borderColor: admin_id === 1 ? '#39FF14' : '#228B22',
      borderWidth: 1,
      hoverBorderWidth: 3,
      hoverBorderColor: '#FF6F61'
    };
  });

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              return `Entries: ${tooltipItem.raw}`;
            }
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          }
        }
      }
    }
  });
};


const createActivityTimelineChart = (ctx, data) => {
  const now = new Date();
  const thirtyDaysAgo = new Date(now);
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const sevenDaysAgo = new Date(now);
  sevenDaysAgo.setDate(now.getDate() - 7);

  const filteredData = data.filter(log => new Date(log.date_changed) >= thirtyDaysAgo);

  const labels = filteredData.map(log => log.date_changed);

  const chart = new Chart(ctx, {
    type: 'scatter',
    data: {
      labels,
      datasets: [{
        label: 'Activity',
        data: filteredData.map(log => ({ x: new Date(log.date_changed), y: `Admin ${log.admin_id}` })),
        backgroundColor: function(context) {
          return context.raw.y === 'Admin 2' ? '#228B22' : '#39FF14'; // Admin ID 2 in green #228B22
        },
        borderColor: function(context) {
          return context.raw.y === 'Admin 2' ? '#228B22' : '#39FF14'; // Admin ID 2 in green #228B22
        },
        borderWidth: 1,
        pointRadius: 6,
        pointHoverRadius: 12,
      }]
    },
    options: {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          },
          title: {
            display: true,
            text: 'Date'
          },
          min: sevenDaysAgo,
          max: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
        },
        y: {
          type: 'category',
          labels: ['Admin 2', 'Admin 1'],
          title: {
            display: false
          },
          ticks: {
            padding: 10  // Adjust padding to move labels above the x-axis
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              // Directly use filteredData array with the appropriate index
              return `Type: ${filteredData[tooltipItem.dataIndex].change_type}, Quantity: ${filteredData[tooltipItem.dataIndex].change_quantity}, Description: ${filteredData[tooltipItem.dataIndex].change_description}`;
            }
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'x',
            speed: 0.1,
          }
        }
      }
    }
  });

  return chart;
};




const createPeopleAddedChart = (ctx, data) => {
  const filteredData = data.filter(log => log.change_type === 'Person added');
  const labels = Array.from(new Set(filteredData.map(log => new Date(log.date_changed).toLocaleDateString())));
  const datasets = [1, 2].map(admin_id => ({
    label: `Admin ${admin_id}`,
    data: labels.map(date => 
      filteredData.filter(log => log.admin_id === admin_id && new Date(log.date_changed).toLocaleDateString() === date).length
    ),
    backgroundColor: admin_id === 1 ? 'rgba(148, 222, 234, 0.5)' : 'rgba(38, 100, 114, 0.5)',
    borderColor: admin_id === 1 ? '#80DEEA' : '#266472',
    borderWidth: 1
  }));

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const logs = filteredData.filter(log => 
                log.admin_id === datasets[tooltipItem.datasetIndex].label.split(' ')[1] &&
                new Date(log.date_changed).toLocaleDateString() === tooltipItem.label
              );
              return logs.map(log => `Person: ${log.change_description}`).join(', ');
            }
          }
        }
      }
    }
  });
};

const createPeopleModifiedChart = (ctx, data) => {
  // Define the change types to filter
  const changeTypes = [
    'Person profile updated',
    'Person categories updated',
    'Person image updated'
  ];

  // Filter the data for the specified change types
  const filteredData = data.filter(log => changeTypes.includes(log.change_type));

  // Get today's date and the past 6 days
  const today = new Date();
  const past7Days = Array.from({ length: 8 }, (_, i) => {
    const date = new Date();
    date.setDate(today.getDate() - i);
    return date.toLocaleDateString();
  }).reverse();

  // Function to get data for a specific admin_id and change_type
  const getDataForAdminAndType = (admin_id, change_type) => {
    return past7Days.map(date =>
      filteredData.filter(log =>
        log.admin_id === admin_id &&
        log.change_type === change_type &&
        new Date(log.date_changed).toLocaleDateString() === date
      ).length
    );
  };

  // Define the datasets for each admin_id and change_type
  const datasets = [1, 2].map(admin_id => 
    changeTypes.map(change_type => ({
      label: `Admin ${admin_id} - ${change_type}`,
      data: getDataForAdminAndType(admin_id, change_type),
      backgroundColor: getBackgroundColor(admin_id, change_type),
      borderColor: getBorderColor(admin_id, change_type),
      borderWidth: 1,
      stack: `Admin ${admin_id}`
    }))
  ).flat();

  // Function to determine background color based on admin_id and change_type
  function getBackgroundColor(admin_id, change_type) {
    const yellowShades = ['rgba(255, 255, 102, 0.5)', 'rgba(255, 255, 0, 0.5)', 'rgba(204, 204, 0, 0.5)'];
    const orangeShades = ['rgba(255, 159, 64, 0.5)', 'rgba(255, 127, 80, 0.5)', 'rgba(255, 69, 0, 0.5)'];

    const index = changeTypes.indexOf(change_type);

    if (admin_id === 1) {
      return yellowShades[index];
    } else if (admin_id === 2) {
      return orangeShades[index];
    }
  }

  // Function to determine border color based on admin_id and change_type
  function getBorderColor(admin_id, change_type) {
    const yellowShades = ['rgba(255, 255, 102, 1)', 'rgba(255, 255, 0, 1)', 'rgba(204, 204, 0, 1)'];
    const orangeShades = ['rgba(255, 159, 64, 1)', 'rgba(255, 127, 80, 1)', 'rgba(255, 69, 0, 1)'];

    const index = changeTypes.indexOf(change_type);

    if (admin_id === 1) {
      return yellowShades[index];
    } else if (admin_id === 2) {
      return orangeShades[index];
    }
  }

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: past7Days,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            stepSize: 1
          },
        },
        x: {
          stacked: true
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const logDate = tooltipItem.label;
              const admin_id = parseInt(datasets[tooltipItem.datasetIndex].label.split(' ')[1]);
              const change_type = datasets[tooltipItem.datasetIndex].label.split(' - ')[1];
              const logs = filteredData.filter(log =>
                log.admin_id === admin_id &&
                log.change_type === change_type &&
                new Date(log.date_changed).toLocaleDateString() === logDate
              );
              return logs.map(log => `Person: ${log.change_description}`).join(', ');
            }
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          }
        }
      }
    }
  });
};





const createPeopleDeletedChart = (ctx, data) => {
  const filteredData = data.filter(log => log.change_type === 'Person deleted');
  const labels = Array.from(new Set(filteredData.map(log => new Date(log.date_changed).toLocaleDateString())));
  const datasets = [1, 2].map(admin_id => ({
    label: `Admin ${admin_id}`,
    data: labels.map(date => 
      filteredData.filter(log => log.admin_id === admin_id && new Date(log.date_changed).toLocaleDateString() === date).length
    ),
    backgroundColor: admin_id === 1 ? 'rgba(255, 119, 152, 0.5)' : 'rgba(165, 42, 42, 0.5)',
    borderColor: admin_id === 1 ? '#FF6384' : '#A52A2A',
    borderWidth: 1
  }));

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const logs = filteredData.filter(log => 
                log.admin_id === datasets[tooltipItem.datasetIndex].label.split(' ')[1] &&
                new Date(log.date_changed).toLocaleDateString() === tooltipItem.label
              );
              return logs.map(log => `Person: ${log.change_description}`).join(', ');
            }
          }
        }
      }
    }
  });
};

// Function to create a base chart for files transcribed and documents added
const createBaseChart = (ctx, data, filterType, chartTitle) => {
  const filteredData = data.filter(log => log.change_type === filterType);
  const labels = Array.from(new Set(filteredData.map(log => new Date(log.date_changed).toLocaleDateString())));

  const adjustBrightness = (color, factor) => {
    const [r, g, b] = color.match(/\d+/g).map(Number);
    return `rgba(${Math.max(0, Math.min(255, r + factor))}, ${Math.max(0, Math.min(255, g + factor))}, ${Math.max(0, Math.min(255, b + factor))}, 0.5)`;
  };

  const baseColor1 = 'rgba(75, 192, 192, 1)';
  const baseColor2 = 'rgba(255, 206, 86, 1)';
  const brightnessFactors = [0, -40, -80, -120];

  const createDataset = (admin_id, dataField, color, yAxisID, index) => {
    const datasetData = labels.map(date =>
      filteredData
        .filter(log => log.admin_id === admin_id && new Date(log.date_changed).toLocaleDateString() === date)
        .reduce((sum, log) => sum + (dataField === 'count' ? 1 : log.change_quantity), 0)
    );

    return datasetData.some(value => value !== 0) ? { // Only include datasets with data
      label: `Admin ${admin_id} (${dataField === 'count' ? 'Count' : 'Chars'})`,
      data: datasetData,
      backgroundColor: adjustBrightness(color, brightnessFactors[index % 4]),
      borderColor: adjustBrightness(color, brightnessFactors[index % 4]),
      borderWidth: 1,
      barPercentage: 0.8,
      categoryPercentage: 0.8,
      yAxisID
    } : null;
  };

  const datasets = [
    createDataset(1, 'count', baseColor1, 'yCount', 0),
    createDataset(1, 'quantity', baseColor1, 'yQuantity', 1),
    createDataset(2, 'count', baseColor2, 'yCount', 2),
    createDataset(2, 'quantity', baseColor2, 'yQuantity', 3),
  ].filter(dataset => dataset !== null); // Filter out empty datasets

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        x: {
          stacked: false,
          title: {
            display: true,
            text: 'Date'
          },
          grid: {
            display: false,
          },
          ticks: {
            callback: function(value, index, values) {
              return labels[index];
            }
          },
          offset: true, // Adds spacing between days
        },
        yCount: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'File Count'
          },
          ticks: {
            stepSize: 1
          }
        },
        yQuantity: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'Character Quantity'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const log = filteredData.find(log => 
                log.admin_id === parseInt(tooltipItem.dataset.label.split(' ')[1]) &&
                new Date(log.date_changed).toLocaleDateString() === tooltipItem.label
              );
              const value = tooltipItem.raw;
              return `${tooltipItem.label}: ${value} ${tooltipItem.dataset.label.includes('Count') ? 'files' : 'characters'}`;
            }
          }
        },
        legend: {
          display: true,
          labels: {
            filter: (legendItem, chartData) => {
              // Keep the admin id in the legend labels
              return legendItem.text.includes('Admin');
            }
          }
        },
        beforeDraw: chart => {
          const ctx = chart.ctx;
          ctx.save();
          ctx.font = 'bold 12px Arial';
          ctx.fillStyle = baseColor1;
          ctx.fillRect(chart.chartArea.right - 100, 10, 15, 15);
          ctx.fillText('Admin 1', chart.chartArea.right - 80, 22);
          ctx.fillStyle = baseColor2;
          ctx.fillRect(chart.chartArea.right - 100, 30, 15, 15);
          ctx.fillText('Admin 2', chart.chartArea.right - 80, 42);
          ctx.restore();
        }
      }
    }
  });
};


const createFilesTranscribedChart = (ctx, data) => {
  return createBaseChart(ctx, data, 'File transcribed', 'Files Transcribed');
};

const createDocumentsAddedChart = (ctx, data) => {
  return createBaseChart(ctx, data, 'Document added', 'Documents Added');
};





const createFilesDeletedChart = (ctx, data) => {
  const filteredData = data.filter(log => log.change_type === 'File deleted');
  const labels = Array.from(new Set(filteredData.map(log => new Date(log.date_changed).toLocaleDateString())));
  const datasets = [1, 2].map(admin_id => ({
    label: `Admin ${admin_id}`,
    data: labels.map(date => 
      filteredData.filter(log => log.admin_id === admin_id && new Date(log.date_changed).toLocaleDateString() === date).length
    ),
    backgroundColor: admin_id === 1 ? 'rgba(153, 122, 255, 0.5)' : 'rgba(103, 62, 205, 0.5)',
    borderColor: admin_id === 1 ? '#9966FF' : '#673ECD',
    borderWidth: 1
  }));

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const logs = filteredData.filter(log => 
                log.admin_id === datasets[tooltipItem.datasetIndex].label.split(' ')[1] &&
                new Date(log.date_changed).toLocaleDateString() === tooltipItem.label
              );
              return logs.map(log => `File: ${log.change_description}`).join(', ');
            }
          }
        }
      }
    }
  });
};

const createAssistantFilesGeneratedChart = (ctx, data) => {
  const filteredData = data.filter(log => log.change_type === 'Assistant file generated');
  const labels = Array.from(new Set(filteredData.map(log => new Date(log.date_changed).toLocaleDateString())));
  const datasets = [1, 2].map(admin_id => ({
    label: `Admin ${admin_id}`,
    data: labels.map(date => 
      filteredData.filter(log => log.admin_id === admin_id && new Date(log.date_changed).toLocaleDateString() === date).length
    ),
    backgroundColor: admin_id === 1 ? 'rgba(255, 226, 136, 0.5)' : 'rgba(205, 176, 36, 0.5)',
    borderColor: admin_id === 1 ? '#FFE288' : '#CDB024',
    borderWidth: 1
  }));

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const logs = filteredData.filter(log => 
                log.admin_id === datasets[tooltipItem.datasetIndex].label.split(' ')[1] &&
                new Date(log.date_changed).toLocaleDateString() === tooltipItem.label
              );
              return logs.map(log => `File: ${log.change_description}`).join(', ');
            }
          }
        }
      }
    }
  });
};

const createChangelogCharts = (data) => {
  const onlineActivityCtx = document.getElementById('onlineActivityChart').getContext('2d');
  createOnlineActivityChart(onlineActivityCtx, data);

  const activityTimelineCtx = document.getElementById('activityTimelineChart').getContext('2d');
  createActivityTimelineChart(activityTimelineCtx, data);

  const peopleAddedCtx = document.getElementById('peopleAddedChart').getContext('2d');
  createPeopleAddedChart(peopleAddedCtx, data);

  const peopleModifiedCtx = document.getElementById('peopleModifiedChart').getContext('2d');
  createPeopleModifiedChart(peopleModifiedCtx, data);

  const peopleDeletedCtx = document.getElementById('peopleDeletedChart').getContext('2d');
  createPeopleDeletedChart(peopleDeletedCtx, data);

  const filesTranscribedCtx = document.getElementById('filesTranscribedChart').getContext('2d');
  createFilesTranscribedChart(filesTranscribedCtx, data);

  const documentsAddedCtx = document.getElementById('documentsAddedChart').getContext('2d');
  createDocumentsAddedChart(documentsAddedCtx, data);

  const filesDeletedCtx = document.getElementById('filesDeletedChart').getContext('2d');
  createFilesDeletedChart(filesDeletedCtx, data);

  const assistantFilesGeneratedCtx = document.getElementById('assistantFilesGeneratedChart').getContext('2d');
  createAssistantFilesGeneratedChart(assistantFilesGeneratedCtx, data);
};

export default createChangelogCharts;
