// src/config/radarChartConfig.js
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export const getRadarChartData = (categories_of_expertise, people) => {
  return categories_of_expertise.map(category => {
    const data = category.items.map(item => {
      return people.filter(person => person.categories_of_expertise.includes(item)).length;
    });

    return {
      labels: category.items,
      datasets: [
        {
          label: category.category,
          data,
          backgroundColor: 'rgba(128, 222, 234, 0.2)',
          borderColor: '#80DEEA',
          borderWidth: 1,
        }
      ],
    };
  });
};

export const radarChartOptions = {
  scales: {
    r: {
      ticks: {
        display: false
      },
      angleLines: {
        display: true,  // Display gridlines from the center
        color: 'rgba(128, 128, 128, 0.5)',  // Customize the color of the gridlines
        lineWidth: 1  // Customize the width of the gridlines
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        title: (context) => context[0].dataset.label,
        label: (context) => `${context.label}: ${context.raw}`
      }
    },
    legend: {
      labels: {
        font: {
          weight: 'bold'
        }
      }
    }
  }
};
