// /src/control/config/expertiseCategories.js

const categoriesOfExpertise = [
  {
    id: 'fitness',
    category: 'Fitness & Physical Training',
    items: [
      'Resistance Training',
      'Strength Training',
      'Functional Training',
      'Cardiovascular Training',
      'Mobility Training',
      'Flexibility Training',
      'High-Intensity Interval Training (HIIT)',
      'Low-Intensity Steady State (LISS)',
      'Circuit Training',
      'CrossFit',
    ],
  },
  {
    id: 'mindBody',
    category: 'Mind-Body Disciplines',
    items: [
      'Yoga',
      'Pilates',
      'Tai Chi',
      'Qigong',
      'Meditation',
      'Breathwork',
      'Mindfulness Practices',
    ],
  },
  {
    id: 'nutritionDiet',
    category: 'Nutrition & Diet',
    items: [
      'General Nutrition',
      'Sports Nutrition',
      'Weight Management',
      'Dietary Planning',
      'Supplementation',
      'Vegetarian and Vegan Diets',
      'Keto and Low-Carb Diets',
      'Paleo Diet',
      'Intermittent Fasting',
    ],
  },
  {
    id: 'mentalHealthWellness',
    category: 'Mental Health & Wellness',
    items: [
      'Stress Management',
      'Anxiety Management',
      'Depression Management',
      'Mindfulness',
      'Wellness Coaching',
      'Life Coaching',
    ],
  },
  {
    id: 'sportsActivities',
    category: 'Sports & Activities',
    items: [
      'Cycling',
      'Swimming',
      'Hiking',
      'Running',
      'Triathlon Training',
      'Marathon Training',
      'Skiing',
      'Snowboarding',
      'Surfing',
      'Rock Climbing',
      'Soccer/Football',
      'Martial Arts',
      'Basketball',
      'Tennis',
      'Volleyball',
      'Golf',
      'Baseball/Softball',
      'Rugby',
      'Boxing',
      'Dance',
      'Archery',
      'Paddle Sports',
      'Equestrian Sports',
      'Watersports',
      'Kayaking/Canoeing/Rowing/SUP',
    ],
  },
  {
    id: 'rehabRecovery',
    category: 'Rehabilitation & Recovery',
    items: [
      'Injury Prevention',
      'Injury Rehabilitation',
      'Post-surgery Rehabilitation',
      'Chronic Pain Management',
      'Performance Recovery',
      'Physical Therapy',
    ],
  },
  {
    id: 'healthConditions',
    category: 'Health Conditions & Management',
    items: [
      'Heart Health',
      'Diabetes Management',
      'Hypertension Management',
      'Arthritis Management',
      'Osteoporosis Management',
      'Disease Prevention',
      'Asthma Management',
      'Sleep Apnea',
      'General hormone health and homeostasis',
      'Womens hormone health',
      'Menopause',
    ],
  },
  {
    id: 'longevity',
    category: 'Longevity & Life Extension',
    items: [
      'Aging Gracefully',
      'Longevity Practices',
      'Biohacking',
    ],
  },
  {
    id: 'specialtyAreas',
    category: 'Specialty Areas',
    items: [
      'Prenatal and Postnatal Fitness',
      'Senior Fitness',
      'Youth Fitness',
      'Athletic Performance Enhancement',
      'Bodybuilding',
      'Powerlifting',
      'Weightlifting',
    ],
  },
  {
    id: 'sleepOptimization',
    category: 'Sleep Optimization',
    items: [
      'Sleep Hygiene',
      'Circadian Rhythm Management',
      'Sleep Apnea Strategies',
      'Insomnia Relief Techniques',
      'Restorative Sleep Practices',
      'Nap Optimization',
      'Dream Analysis',
      'Technology for Better Sleep (Apps, Wearables, Mattresses)',
      'Environmental Adjustments for Sleep',
    ],
  },
];

export default categoriesOfExpertise;
