// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

:root {
  --background-color: #121212;
  --secondary-color: #1C1C1E;
  --text-icon-color: #E0E0E0;
  --brand-identity-color: #80DEEA;
  transition: background-color 0.5s, color 0.5s;
}

.dark-theme {
  --background-color: #121212;
  --secondary-color: #1C1C1E;
  --text-icon-color: #E0E0E0;
  --brand-identity-color: #80DEEA;
}

.light-theme {
  --background-color: #FFFFFF;
  --secondary-color: #E8E9EC;
  --text-icon-color: #333333;
  --brand-identity-color: #80DEEA;
}

.App {
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-icon-color);
  transition: background-color 0.5s, color 0.5s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-icon-color);
}

.home {
  background-color: var(--background-color);
}

.App-link {
  color: var(--brand-identity-color);
}

body {
  font-family: 'Roboto', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;EAC1B,+BAA+B;EAC/B,6CAA6C;AAC/C;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,6BAA6B;EAC7B,6CAA6C;AAC/C;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,6BAA6B;AAC/B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["/* App.css */\n\n:root {\n  --background-color: #121212;\n  --secondary-color: #1C1C1E;\n  --text-icon-color: #E0E0E0;\n  --brand-identity-color: #80DEEA;\n  transition: background-color 0.5s, color 0.5s;\n}\n\n.dark-theme {\n  --background-color: #121212;\n  --secondary-color: #1C1C1E;\n  --text-icon-color: #E0E0E0;\n  --brand-identity-color: #80DEEA;\n}\n\n.light-theme {\n  --background-color: #FFFFFF;\n  --secondary-color: #E8E9EC;\n  --text-icon-color: #333333;\n  --brand-identity-color: #80DEEA;\n}\n\n.App {\n  text-align: center;\n  background-color: var(--background-color);\n  color: var(--text-icon-color);\n  transition: background-color 0.5s, color 0.5s;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: var(--secondary-color);\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: var(--text-icon-color);\n}\n\n.home {\n  background-color: var(--background-color);\n}\n\n.App-link {\n  color: var(--brand-identity-color);\n}\n\nbody {\n  font-family: 'Roboto', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
