// Vitalise.js
import React, { useState } from 'react';
import './Vitalise.css';
import logoColor from '../assets/vitalise/vitalise_color.png';
import googlePlayImage from '../assets/vitalise/google_play.png';
import appStoreImage from '../assets/vitalise/app_store.png';
import mockupTestImage from '../assets/vitalise/mockup_test.png';
import { VitaliseReleaseNotes } from '../other/VitaliseReleaseNotes';

const Vitalise = () => {
  const [expandedNote, setExpandedNote] = useState(null);

  const handleNoteClick = (version) => {
    setExpandedNote(expandedNote === version ? null : version);
  };

  return (
    <div className="vitalise">
      <img src={logoColor} alt="App Mockup" className="logo-image" />
      <p>VitaliseAI is a healthtech app utilizing AI to tailor healthcare for every individual. Our mission is to synthesize leading expertise in health, fitness, nutrition, and longevity into a mobile app — a pocket-sized package offering easy access anytime at an affordable cost.</p>
      <p>More info and download coming soon</p>
      <img src={mockupTestImage} alt="App Mockup" className="mockup-image" />
      <div className="store-buttons">
        <div className="store-button">
          <img src={googlePlayImage} alt="Get it on Google Play" className="store-image" />
          <div className="overlay">Launching soon</div>
        </div>
        <div className="store-button">
          <img src={appStoreImage} alt="Download on the App Store" className="store-image" />
          <div className="overlay">Launching soon</div>
        </div>
      </div>
      <div className="release-notes">
        <h2>Release Notes</h2>
        {VitaliseReleaseNotes.slice().reverse().map(note => (
          <div key={note.version} className="release-note">
            <button
              className={`release-note-button ${expandedNote === note.version ? 'selected' : ''}`}
              onClick={() => handleNoteClick(note.version)}
            >
              {note.version}
            </button>
            {expandedNote === note.version && (
              <div className="release-note-content">
                {note.sections.map((section, index) => (
                  <div key={index} className="release-note-section">
                    <h3>{section.title}</h3>
                    <ul>
                      {section.bullets.map((bullet, idx) => (
                        <li key={idx}>{bullet}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vitalise;