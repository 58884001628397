// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Control.css */

.control-panel {
  text-align: center;
  min-height: calc(100vh - (76px));
  overflow: hidden;
}
  
.control-table {
  margin: 0 auto;
  border-collapse: collapse;
}
  
.control-table th, .control-table td {
  border: 1px solid var(--text-icon-color);
  padding: 8px;
}

.text {
  color: var(--text-icon-color);
}

.control-button {
  background: var(--text-icon-color);
  color: var(--secondary-color);
  font-size: 12px;
  font-weight: bold;
  border: none;
  padding: 5px;
  margin: 2px;
  border-radius: 5px;
  transition: background 0.3s ease, border-radius 0.3s ease;
  cursor: pointer;
  width: 100%;
  align-self: center;
}
.control-button:hover {
  background: var(--brand-identity-color);
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/control/Control.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,kBAAkB;EAClB,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kCAAkC;EAClC,6BAA6B;EAC7B,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,yDAAyD;EACzD,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,uCAAuC;EACvC,mBAAmB;AACrB","sourcesContent":["/* Control.css */\n\n.control-panel {\n  text-align: center;\n  min-height: calc(100vh - (76px));\n  overflow: hidden;\n}\n  \n.control-table {\n  margin: 0 auto;\n  border-collapse: collapse;\n}\n  \n.control-table th, .control-table td {\n  border: 1px solid var(--text-icon-color);\n  padding: 8px;\n}\n\n.text {\n  color: var(--text-icon-color);\n}\n\n.control-button {\n  background: var(--text-icon-color);\n  color: var(--secondary-color);\n  font-size: 12px;\n  font-weight: bold;\n  border: none;\n  padding: 5px;\n  margin: 2px;\n  border-radius: 5px;\n  transition: background 0.3s ease, border-radius 0.3s ease;\n  cursor: pointer;\n  width: 100%;\n  align-self: center;\n}\n.control-button:hover {\n  background: var(--brand-identity-color);\n  border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
