// src/other/ThemeWrapper.js
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const ThemeWrapper = () => {
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark-theme';
    document.getElementById('theme-wrapper').className = savedTheme;
  }, []);

  return <div id="theme-wrapper" className="dark-theme"><Outlet /></div>;
};

export default ThemeWrapper;