// src/other/Layout.js
import React, { useState, useContext } from 'react';
import '../App.css';

import { Routes, Route, useLocation } from 'react-router-dom';
import ThemeWrapper from './ThemeWrapper';

import Home from '../components/Home';
import Vitalise from '../components/Vitalise';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Control from '../control/Control';
import Dashboard from '../control/Dashboard';
import People from '../control/People';
import Files from '../control/Files';
import Admin from '../control/Admin';
import Sidebar from '../control/components/Sidebar';
import { QueueContext } from '../context/QueueContext';

const Layout = () => {
  const location = useLocation();
  const hideFooterRoutes = ["/control", "/dashboard", "/people", "/files", "/admin"];
  const showSidebarRoutes = ["/dashboard", "/people", "/files", "/admin"];
  const { isFilesQueued, setIsFilesQueued } = useContext(QueueContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vitalise" element={<Vitalise />} />
        <Route path="/control" element={<Control />} />
        <Route element={<ThemeWrapper />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/people" element={<People />} />
          <Route path="/files" element={<Files isFilesQueued={isFilesQueued} setIsFilesQueued={setIsFilesQueued} />} />
          <Route path="/admin" element={<Admin />} />
        </Route>
      </Routes>
      {!hideFooterRoutes.includes(location.pathname) && <Footer />}
      {showSidebarRoutes.includes(location.pathname) && (
        <ThemeWrapper>
          <Sidebar 
            isFilesQueued={isFilesQueued} 
            setIsFilesQueued={setIsFilesQueued} 
            isSidebarOpen={isSidebarOpen} 
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </ThemeWrapper>
      )}
    </div>
  );
};

export default Layout;