// Home.js
import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { Boxes } from './Boxes';
//import { useForm } from 'react-hook-form';

//import emailjs from 'emailjs-com';
//import ReCAPTCHA from 'react-google-recaptcha';

//import TextField from '@mui/material/TextField'; // Material-UI import
//import Button from '@mui/material/Button'; // Material-UI Button
//import Box from '@mui/material/Box'; // Material-UI Box for layout

import app1logo from '../assets/Icon_App.png';
import rasimage from '../assets/ras.png';
import mikimage from '../assets/mik.png';

import mikrologo from '../assets/mikrolegat.png';

function Home() {
  //const reCaptchaKey = "6Lf8150pAAAAAKUAtGF9ZpjfbbtXJLu_o6JEFJ2f"; // replace with env variable
  // secret key: 6Lf8150pAAAAAPi5hNBoL-d5g3yl0yChJvQjbFK_
  //const recaptchaRef = useRef();
  //const [isVerified, setIsVerified] = useState(false);

  const [emailVisible, setEmailVisible] = useState(false);

  //const [showCaptcha, setShowCaptcha] = useState(false);
  //const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

  const teamMember1URL = "https://rasmusjuhl.com/";
  const teamMember2URL = "https://www.linkedin.com/in/mikkel-schj%C3%B8tt-juhl-b2ab172b7/";

  // Helper function to open links
  const openLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Watch the form fields
  //const watchedFields = watch(['name', 'email', 'message']);

  /*
  useEffect(() => {
    // Check if all fields are filled
    const areFieldsFilled = watchedFields.name && watchedFields.email && watchedFields.message;
    setShowCaptcha(areFieldsFilled);
  }, [watchedFields.name, watchedFields.email, watchedFields.message]); // Depend on individual fields to re-check whenever they change

  const sendEmail = (data) => {
    if (!isVerified) {
      alert('Please verify you are a human!');
      return;
    }

    emailjs.send('service_x17mpve', 'template_r9qm54n', data, 'YOUR_USER_ID')
      .then((result) => {
          alert('Message sent successfully!');
          reset(); // This will also clear watchedFields, so the CAPTCHA will be hidden again
          recaptchaRef.current.reset();
          setIsVerified(false);
      }, (error) => {
          alert('Failed to send the message, please try again.');
          console.log(error.text);
      });
  };


  const onReCaptchaChange = (token) => {
    setIsVerified(!!token);
  };
  */

  const handleEmailClick = () => {
    setEmailVisible(true);
  };

  return (
    <div className="home">
      <div className="box-grid">
        <div className="box-grid-overlay"></div>
        <Boxes className="background-boxes" />
        <div className="text-overlay">
          <h1>Mikras Ventures</h1>
          <p>Our mission is simple: innovate and create impactful technology</p>
        </div>
      </div>



      <div className="about-us">
        <h2>About Us</h2>
        <div className="about-us-content">
          <p>Welcome to Mikras Ventures. We are a team of entrepreneurs, each owning an equal part of the company through our individual holding companies. Our mission is simple: innovate and create impactful technology. Our current project is 'Vitalise.ai' - an application aimed at enhancing health and longevity through artificial intelligence. We bring together diverse skills in AI, business, and healthcare to develop solutions that matter. At Mikras Ventures, we're excited to make a difference with technology that improves lives.</p>
          <div className="team">
            <div className="team-member" onClick={() => openLink(teamMember1URL)}>
              <div className="team-member-1-description">
                <p>Rasmus Juhl</p>
                <p>CEO & Co-Founder</p>
                <p>BSc International Business, CBS</p>
                <p>Full-Stack developer, Business strategy</p>
              </div>
              <img src={rasimage} alt="Team Member 1" className="team-member-image" />
            </div>
            <div className="team-member" onClick={() => openLink(teamMember2URL)}>
              <img src={mikimage} alt="Team Member 2" className="team-member-image" />
              <div className="team-member-2-description">
                <p>Mikkel Juhl</p>
                <p>CSO & Co-Founder</p>
                <p>BSc & MSc Sports Science & Health, SDU</p>
                <p>Exercise physiology, health & performance</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-ventures">
        <h2>Our Ventures</h2>
          <div className="content-box">
            <img src={app1logo} className="vitalise-image" alt="content" />
            <p className="content-text">Vitalise.ai is a healthtech app utilizing AI to tailor healthcare for every individual. Our mission is to synthesize leading expertise in health, fitness, nutrition, and longevity into a mobile app — a pocket-sized package offering easy access anytime at an affordable cost.</p>
            <button className="read-more" onClick={() => window.open('https://vitalise.ai/', '_blank')}>VISIT VITALISE.AI</button>
          </div>
      </div>

      <div className="our-supporters">
        <h2>Our Supporters</h2>
          <div className="support-content-box">
            <img src={mikrologo} className="content-image" alt="content" />
          </div>
      </div>

      {/* Contact form 
      <div className="contact-form">
        <h2>Contact Us</h2>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { 
              m: 1,
              width: {
                xs: '90%',
                sm: '90%',
                md: '50%',
                lg: '50%',
                xl: '25%',
              }
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(sendEmail)}
        >
          <TextField
            label="Name"
            variant="outlined"
            {...register('name', { required: true })}
            error={errors.name}
            helperText={errors.name && 'Name is required'}
            InputLabelProps={{
              style: { color: '#80DEEA' },
            }}
            InputProps={{
              style: { color: '#E0E0E0' },
              classes: { focused: 'custom-focused-class' },
            }}
          />
          <TextField
            label="Email"
            variant="outlined"
            {...register('email', { required: true, pattern: /^\S+@\S+\.\S+$/ })}
            error={errors.email}
            helperText={errors.email && 'Valid email is required'}
            InputLabelProps={{
              style: { color: '#80DEEA' },
            }}
            InputProps={{
              style: { color: '#E0E0E0' },
              classes: { focused: 'custom-focused-class' },
            }}
          />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            {...register('message', { required: true })}
            error={errors.message}
            helperText={errors.message && 'Message is required'}
            InputLabelProps={{
              style: { color: '#80DEEA' },
            }}
            InputProps={{
              style: { color: '#E0E0E0' },
              classes: { focused: 'custom-focused-class' },
            }}
          />
          {showCaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaKey}
              onChange={onReCaptchaChange}
              style={{ margin: "10px 0" }}
            />
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 2,
            }}
          >
            Send
          </Button>
        </Box>
      </div>
      */}

      <div className="contact-form">
        <h2>Contact Us</h2>
        <div className="email-container">
          {emailVisible ? (
            <p className="email-address">contact@mikrasventures.com</p>
          ) : (
            <button className="email-button" onClick={handleEmailClick}>
              Email
            </button>
          )}
        </div>
      </div>

    </div>
  );
}

export default Home;
