import React, { useState, useEffect, useRef } from 'react';
import './CommunicationSection.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, CompositeDecorator } from 'draft-js';
import 'draft-js/dist/Draft.css';

import axios from '../helpers/axiosWithAuth';

const MAX_PINNED_ITEMS = 5;
const MAX_NEWS_ITEMS = 10;
const MAX_OLD_ITEMS = 100;

// Link decorator
const linkDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

function Link(props) {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{color: 'blue', textDecoration: 'underline'}}>
      {props.children}
    </a>
  );
}

const CommunicationSection = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

    const [editorState, setEditorState] = useState(EditorState.createEmpty(linkDecorator));
    const [activeSection, setActiveSection] = useState(null);
    const [newsItems, setNewsItems] = useState({
        pinned: [],
        pinned_link: [],
        news: [],
        news_link: [],
        old: [],
        old_link: []
    });
    const [isAddingNews, setIsAddingNews] = useState(false);
    const [newNewsItem, setNewNewsItem] = useState({
        type: 'news',
        title: '',
        short_description: '',
        content: '',
        image: null,
        display_rank: 1,
        link_to_previous: ''
    });
    const [selectedNewsItem, setSelectedNewsItem] = useState(null);
    const [error, setError] = useState('');
    const [editingNewsItem, setEditingNewsItem] = useState(null);
    const [removeImage, setRemoveImage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (activeSection === 'news') {
            fetchNewsItems();
        }
    }, [activeSection]);

    const fetchNewsItems = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/get-news-items`);
            const items = response.data;
            const newNewsItems = {
                pinned: items.filter(item => item.type === 'pinned').sort((a, b) => a.display_rank - b.display_rank),
                pinned_link: items.filter(item => item.type === 'pinned_link').sort((a, b) => a.display_rank - b.display_rank),
                news: items.filter(item => item.type === 'news').sort((a, b) => a.display_rank - b.display_rank),
                news_link: items.filter(item => item.type === 'news_link').sort((a, b) => a.display_rank - b.display_rank),
                old: items.filter(item => item.type === 'old').sort((a, b) => a.display_rank - b.display_rank),
                old_link: items.filter(item => item.type === 'old_link').sort((a, b) => a.display_rank - b.display_rank)
            };
            setNewsItems(newNewsItems);
        } catch (error) {
            console.error('Error fetching news items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'short_description') {
            setNewNewsItem(prev => ({ ...prev, [name]: value.slice(0, 250) }));
        } else {
            setNewNewsItem(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            if (editingNewsItem) {
                setEditingNewsItem({...editingNewsItem, image: file});
            } else {
                setNewNewsItem({...newNewsItem, image: file});
            }
            setRemoveImage(false);
        }
    };

    const clearNewsItemForm = () => {
        setNewNewsItem({
            type: 'news',
            title: '',
            short_description: '',
            content: '',
            image: null,
            display_rank: 1,
            link_to_previous: ''
        });
        setEditorState(EditorState.createEmpty(linkDecorator));
        setEditingNewsItem(null);
        setImagePreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setError('');
    };

    const handleAddNewsItem = async (e) => {
        e.preventDefault();
        setError('');

        const isLinkType = newNewsItem.type.includes('link');

        if (!newNewsItem.title || !newNewsItem.short_description || 
            (!isLinkType && !newNewsItem.content)) {
            setError('Please fill out all required fields.');
            return;
        }

        try {
            const formData = new FormData();
            Object.keys(newNewsItem).forEach(key => {
                if (key === 'content' && !isLinkType) {
                    formData.append(key, JSON.stringify(convertToRaw(editorState.getCurrentContent())));
                } else if (key !== 'content' && !(isLinkType && key === 'image')) {
                    const value = key === 'link_to_previous' && newNewsItem[key] === '' ? null : newNewsItem[key];
                    formData.append(key, value === null ? '' : value);
                }
            });

            await axios.post(`${API_BASE_URL}/add-news-item`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            clearNewsItemForm();
            setIsAddingNews(false);
            fetchNewsItems();
        } catch (error) {
            console.error('Error adding news item:', error);
            setError('Failed to add news item. Please try again.');
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination, draggableId } = result;
    
        if (!destination) {
            console.log("No destination, drag cancelled");
            return;
        }
    
        const sourceType = source.droppableId;
        const destType = destination.droppableId;
    
        console.log(`Moving item from ${sourceType} to ${destType}`);
    
        const newNewsItems = { ...newsItems };
    
        // Combine regular and link items for the source type
        const sourceItems = [...newNewsItems[sourceType], ...newNewsItems[`${sourceType}_link`]]
            .filter(item => item !== null)
            .sort((a, b) => a.display_rank - b.display_rank);
    
        // Find the reordered item
        const reorderedItem = sourceItems.find(item => item.id.toString() === draggableId);
    
        if (!reorderedItem) {
            console.error("Couldn't find the dragged item");
            return;
        }
    
        // Remove the reordered item from the source items
        const updatedSourceItems = sourceItems.filter(item => item.id !== reorderedItem.id);
    
        if (sourceType === destType) {
            // If moving within the same column
            updatedSourceItems.splice(destination.index, 0, reorderedItem);
            
            // Update display_rank for all items in the column
            updatedSourceItems.forEach((item, index) => {
                item.display_rank = index + 1;
            });
    
            // Separate items back into regular and link arrays
            newNewsItems[sourceType] = updatedSourceItems.filter(item => !item.type.includes('link'));
            newNewsItems[`${sourceType}_link`] = updatedSourceItems.filter(item => item.type.includes('link'));
        } else {
            // If moving to a different column
            // Combine regular and link items for the destination type
            const destItems = [...newNewsItems[destType], ...newNewsItems[`${destType}_link`]]
                .filter(item => item !== null)
                .sort((a, b) => a.display_rank - b.display_rank);
    
            // Update the type if moved to a different column, preserving link status
            const isLink = reorderedItem.type.includes('link');
            reorderedItem.type = isLink ? `${destType}_link` : destType;
    
            destItems.splice(destination.index, 0, reorderedItem);
    
            // Update display_rank for all items in the destination column
            destItems.forEach((item, index) => {
                item.display_rank = index + 1;
            });
    
            // Separate items back into regular and link arrays
            newNewsItems[destType] = destItems.filter(item => !item.type.includes('link'));
            newNewsItems[`${destType}_link`] = destItems.filter(item => item.type.includes('link'));
    
            // Update the source column's display_ranks
            updatedSourceItems.forEach((item, index) => {
                item.display_rank = index + 1;
            });
            newNewsItems[sourceType] = updatedSourceItems.filter(item => !item.type.includes('link'));
            newNewsItems[`${sourceType}_link`] = updatedSourceItems.filter(item => item.type.includes('link'));
        }
    
        console.log("Updated news items:", newNewsItems);
        setNewsItems(newNewsItems);
    };

    const handleOpenNewsItem = (id) => {
        const item = [...newsItems.pinned, ...newsItems.news, ...newsItems.old].find(item => item.id === id);
        if (item) {
            const contentState = convertFromRaw(JSON.parse(item.content));
            const editorState = EditorState.createWithContent(contentState);
            setSelectedNewsItem({...item, editorState});
            
            // Scroll to the selected news item
            setTimeout(() => {
                const element = document.getElementById('selected-news-item');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        }
    };

    const handleEditNewsItem = (id) => {
        const item = Object.values(newsItems).flat().find(item => item.id === id);
        if (item) {
            const isLinkType = item.type.includes('link');
            let editorState;
            if (!isLinkType && item.content) {
                const contentState = convertFromRaw(JSON.parse(item.content));
                editorState = EditorState.createWithContent(contentState, linkDecorator);
            } else {
                editorState = EditorState.createEmpty(linkDecorator);
            }
            setEditingNewsItem({...item, editorState});
            setIsAddingNews(true);
            setRemoveImage(false);
        }
    };

    const handleDeleteNewsItem = async (id) => {
        try {
            await axios.delete(`${API_BASE_URL}/delete-news-item/${id}`);
            // Remove the item from the state and update display_ranks
            const updatedNewsItems = { ...newsItems };
            Object.keys(updatedNewsItems).forEach(type => {
                const index = updatedNewsItems[type].findIndex(item => item.id === id);
                if (index !== -1) {
                    updatedNewsItems[type].splice(index, 1);
                    // Update display_ranks for remaining items
                    updatedNewsItems[type].forEach((item, i) => {
                        item.display_rank = i + 1;
                    });
                }
            });
            setNewsItems(updatedNewsItems);
            setEditingNewsItem(null);
            setIsAddingNews(false);
            clearNewsItemForm();
        } catch (error) {
            console.error('Error deleting news item:', error);
        }
    };

    const handleUpdateNewsItem = async (e) => {
        e.preventDefault();
        setError('');

        const isLinkType = editingNewsItem.type.includes('link');

        if (!editingNewsItem.title || !editingNewsItem.short_description || 
            (!isLinkType && !editingNewsItem.content)) {
            setError('Please fill out all required fields.');
            return;
        }

        try {
            const formData = new FormData();
            Object.keys(editingNewsItem).forEach(key => {
                if (key === 'content' && !isLinkType) {
                    formData.append(key, JSON.stringify(convertToRaw(editingNewsItem.editorState.getCurrentContent())));
                } else if (key !== 'editorState' && key !== 'image') {
                    const value = key === 'link_to_previous' && editingNewsItem[key] === '' ? null : editingNewsItem[key];
                    formData.append(key, value === null ? '' : value);
                }
            });

            if (editingNewsItem.image instanceof File) {
                formData.append('image', editingNewsItem.image);
            }

            formData.append('remove_image', removeImage);

            await axios.put(`${API_BASE_URL}/update-news-item/${editingNewsItem.id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            setIsAddingNews(false);
            setEditingNewsItem(null);
            setRemoveImage(false);
            fetchNewsItems();
        } catch (error) {
            console.error('Error updating news item:', error);
            setError('Failed to update news item. Please try again.');
        }
    };

    const renderNewsColumn = (type) => {
        const maxItems = type === 'pinned' ? MAX_PINNED_ITEMS : type === 'news' ? MAX_NEWS_ITEMS : MAX_OLD_ITEMS;
        
        // Combine regular and link items
        const items = [...newsItems[type], ...newsItems[`${type}_link`]]
            .filter(item => item !== null)
            .sort((a, b) => a.display_rank - b.display_rank);
        
        // Add only one empty cell at the end if there's room
        const filledCells = items.length < maxItems ? [...items, null] : items;
    
        return (
            <div className="news-column">
                <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
                <p className="max-items">Max items: {maxItems}</p>
                <Droppable droppableId={type}>
                    {(provided) => (
                        <div 
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            className="news-grid"
                        >
                            {filledCells.map((item, index) => {
                                const itemId = item ? `${item.id}` : `empty-${type}-${index}`;
                                const isLinkType = item && item.type.includes('link');
                                return (
                                    <Draggable 
                                        key={itemId}
                                        draggableId={itemId}
                                        index={index}
                                        isDragDisabled={!item}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`news-item ${!item ? 'empty' : ''} ${isLinkType ? 'link-item' : ''} ${snapshot.isDragging ? 'dragging' : ''}`}
                                            >
                                                {item ? (
                                                    <>
                                                        {item.title}
                                                        <div className="news-item-buttons">
                                                            {!isLinkType && (
                                                                <button className="news-item-button open" onClick={() => handleOpenNewsItem(item.id)}>Open</button>
                                                            )}
                                                            <button className="news-item-button edit" onClick={() => handleEditNewsItem(item.id)}>Edit</button>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        );
    };

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        if (contentState.getBlockMap().size > 0) {
            const rawContent = JSON.stringify(convertToRaw(contentState));
            setNewNewsItem(prev => ({ ...prev, content: rawContent }));
        }
    };

    const handleEditorCommand = (command) => {
        const newState = RichUtils.toggleInlineStyle(editorState, command.toUpperCase());
        setEditorState(newState);
    };

    const handleAddLink = () => {
        const selection = editorState.getSelection();
        const link = window.prompt('Enter a URL:');
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
            return;
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    };

    const saveNewsItemsOrder = async () => {
        const updatedNewsItems = {
            pinned: [],
            news: [],
            old: []
        };
    
        ['pinned', 'news', 'old'].forEach(type => {
            const combinedItems = [...newsItems[type], ...newsItems[`${type}_link`]]
                .filter(item => item !== null)
                .sort((a, b) => a.display_rank - b.display_rank);
    
            combinedItems.forEach((item, index) => {
                updatedNewsItems[type].push({
                    ...item,
                    display_rank: index + 1
                });
            });
        });
    
        try {
            await axios.post(`${API_BASE_URL}/update-news-items-order`, updatedNewsItems);
            fetchNewsItems(); // Fetch the updated items from the server
            alert('News items order saved successfully!');
        } catch (error) {
            console.error('Error saving news items order:', error);
            alert('Failed to save news items order. Please try again.');
        }
    };

    const handleRemoveImage = () => {
        setRemoveImage(true);
        setImagePreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (editingNewsItem) {
            setEditingNewsItem({...editingNewsItem, image: null});
        } else {
            setNewNewsItem({...newNewsItem, image: null});
        }
    };

    const renderNewsSection = () => {
        if (isLoading) {
            return <div>Loading news items...</div>;
        }
        return (
            <div className="news-section">
                <button className="add-news-button" onClick={() => {
                    if (isAddingNews) {
                        clearNewsItemForm();
                    }
                    setIsAddingNews(!isAddingNews);
                }}>
                    {isAddingNews ? 'Cancel' : 'Add News Item'}
                </button>
                {(isAddingNews || editingNewsItem) && (
                    <form onSubmit={editingNewsItem ? handleUpdateNewsItem : handleAddNewsItem} className="add-news-form">
                        <div className="form-row">
                            <select 
                                name="type" 
                                value={editingNewsItem ? editingNewsItem.type : newNewsItem.type} 
                                onChange={editingNewsItem ? 
                                    (e) => setEditingNewsItem({...editingNewsItem, type: e.target.value}) : 
                                    handleInputChange
                                }
                            >
                                <option value="pinned">Pinned</option>
                                <option value="pinned_link">Pinned Link</option>
                                <option value="news">News</option>
                                <option value="news_link">News Link</option>
                                <option value="old">Old</option>
                                <option value="old_link">Old Link</option>
                            </select>
                            <input
                                type="text"
                                name="title"
                                value={editingNewsItem ? editingNewsItem.title : newNewsItem.title}
                                onChange={editingNewsItem ? 
                                    (e) => setEditingNewsItem({...editingNewsItem, title: e.target.value}) : 
                                    handleInputChange
                                }
                                placeholder="Title"
                            />
                        </div>
                        <textarea
                            name="short_description"
                            value={editingNewsItem ? editingNewsItem.short_description : newNewsItem.short_description}
                            onChange={editingNewsItem ? 
                                (e) => setEditingNewsItem({...editingNewsItem, short_description: e.target.value.slice(0, 250)}) : 
                                handleInputChange
                            }
                            placeholder={(editingNewsItem ? editingNewsItem.type : newNewsItem.type).includes('link') ? "URL/Link" : "Short Description"}
                            maxLength={150}
                            className="short-description-input"
                        />
                        {!(editingNewsItem ? editingNewsItem.type : newNewsItem.type).includes('link') && (
                            <>
                                <div className="editor-toolbar">
                                    <button type="button" onClick={() => handleEditorCommand('bold')} className={editorState.getCurrentInlineStyle().has('BOLD') ? 'active' : ''}>Bold</button>
                                    <button type="button" onClick={() => handleEditorCommand('italic')} className={editorState.getCurrentInlineStyle().has('ITALIC') ? 'active' : ''}>Italic</button>
                                    <button type="button" onClick={() => handleEditorCommand('underline')} className={editorState.getCurrentInlineStyle().has('UNDERLINE') ? 'active' : ''}>Underline</button>
                                    <button type="button" onClick={handleAddLink}>Add Link</button>
                                </div>
                                <div className="editor-container">
                                    <Editor
                                        editorState={editingNewsItem ? editingNewsItem.editorState : editorState}
                                        onChange={editingNewsItem ?
                                            (newState) => setEditingNewsItem({...editingNewsItem, editorState: newState}) :
                                            setEditorState
                                        }
                                    />
                                </div>
                            </>
                        )}
                        <div className="image-section">
                            {editingNewsItem && editingNewsItem.has_image && !removeImage && !imagePreview && (
                                <div className="current-image">
                                    <img src={editingNewsItem.image_url} alt="Current news item image" />
                                    <button type="button" className="remove-image-button" onClick={handleRemoveImage}>Remove Image</button>
                                </div>
                            )}
                            {imagePreview && (
                                <div className="image-preview">
                                    <img src={imagePreview} alt="Image preview" />
                                    <button type="button" className="remove-image-button" onClick={handleRemoveImage}>Remove Image</button>
                                </div>
                            )}
                            {!imagePreview && (editingNewsItem ? !editingNewsItem.has_image || removeImage : true) && (
                                <div className="file-input-wrapper">
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        ref={fileInputRef}
                                        id="file-input"
                                    />
                                    <label htmlFor="file-input" className="file-input-label">Choose Image</label>
                                </div>
                            )}
                            {removeImage && <p>Image will be removed upon saving.</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="display_rank">Display Rank</label>
                            <input
                                type="number"
                                id="display_rank"
                                name="display_rank"
                                value={editingNewsItem ? editingNewsItem.display_rank : newNewsItem.display_rank}
                                onChange={editingNewsItem ? 
                                    (e) => setEditingNewsItem({...editingNewsItem, display_rank: Math.max(1, parseInt(e.target.value) || 1)}) : 
                                    (e) => setNewNewsItem({...newNewsItem, display_rank: Math.max(1, parseInt(e.target.value) || 1)})
                                }
                                placeholder="Display Rank"
                                min="1"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="link_to_previous">Link to Previous In Series</label>
                            <input
                                type="text"
                                id="link_to_previous"
                                name="link_to_previous"
                                value={editingNewsItem ? editingNewsItem.link_to_previous || '' : newNewsItem.link_to_previous || ''}
                                onChange={editingNewsItem ? 
                                    (e) => setEditingNewsItem({...editingNewsItem, link_to_previous: e.target.value}) : 
                                    handleInputChange
                                }
                                placeholder="Link to Previous In Series"
                            />
                        </div>
                        {editingNewsItem && (
                            <div className="form-group">
                                <label htmlFor="news_link">News Link</label>
                                <div className="news-link-container">
                                    <input
                                        type="text"
                                        id="news_link"
                                        value={`https://vitalise.ai/news/${editingNewsItem.title.replace(/\s+/g, '_')}_${editingNewsItem.id}`}
                                        readOnly
                                    />
                                    <button 
                                        type="button" 
                                        className="copy-link-button"
                                        onClick={() => navigator.clipboard.writeText(`https://vitalise.ai/news/${editingNewsItem.title.replace(/\s+/g, '_')}_${editingNewsItem.id}`)}
                                    >
                                        Copy Link
                                    </button>
                                </div>
                            </div>
                        )}
                        <button type="submit" className="submit-button">{editingNewsItem ? 'Update News Item' : 'Save News Item'}</button>
                        {editingNewsItem && (
                            <button type="button" className="delete-button" onClick={() => handleDeleteNewsItem(editingNewsItem.id)}>Delete News Item</button>
                        )}
                        {error && <p className="error">{error}</p>}
                    </form>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="news-columns">
                        {renderNewsColumn('pinned')}
                        {renderNewsColumn('news')}
                        {renderNewsColumn('old')}
                    </div>
                </DragDropContext>
                <button className="save-order-button" onClick={saveNewsItemsOrder}>Save News Items Order</button>
                {selectedNewsItem && (
                    <div id="selected-news-item" className="selected-news-item">
                        <div className="selected-news-item-header">
                            <h2>{selectedNewsItem.title}</h2>
                            <button className="close-button" onClick={() => setSelectedNewsItem(null)}>Close</button>
                        </div>
                        <p>{selectedNewsItem.short_description}</p>
                        <div className="news-content">
                            <Editor
                                editorState={selectedNewsItem.editorState}
                                readOnly={true}
                            />
                        </div>
                        {selectedNewsItem.has_image && <img src={selectedNewsItem.image_url} alt={selectedNewsItem.title} />}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="communication-section">
            <div className="communication-buttons">
                <button 
                    className={activeSection === 'notifications' ? 'active' : ''}
                    onClick={() => setActiveSection('notifications')}
                >
                    Notifications Manager
                </button>
                <button 
                    className={activeSection === 'emails' ? 'active' : ''}
                    onClick={() => setActiveSection('emails')}
                >
                    Emails
                </button>
                <button 
                    className={activeSection === 'news' ? 'active' : ''}
                    onClick={() => setActiveSection('news')}
                >
                    News Items
                </button>
            </div>
            {activeSection && (
                <div className="communication-content">
                    {activeSection === 'notifications' && <div>Notifications Manager Content</div>}
                    {activeSection === 'emails' && <div>Emails Content</div>}
                    {activeSection === 'news' && renderNewsSection()}
                </div>
            )}
        </div>
    );
};

export default CommunicationSection;