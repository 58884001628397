// src/control/components/SideBar.js
import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { FaChevronLeft, FaChevronRight, FaMoon, FaSun, FaArrowLeft } from 'react-icons/fa';

import QueueSidebar from './QueueSidebar';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const SideBar = ({ isSidebarOpen, setIsSidebarOpen, isFilesQueued, selectedPersonId, setIsFilesQueued, fetchFilesForPerson, fetchPersonFilesUpdateStatus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark-theme');
  const headerHeight = 76;

  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    setIsSidebarOpen(newState);
  };

  const isActive = (path) => location.pathname === path;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const toggleTheme = () => {
    const themeWrapper = document.getElementById('theme-wrapper');
    const newThemeClass = isDarkMode ? 'light-theme' : 'dark-theme';
  
    if (themeWrapper) {
      themeWrapper.className = newThemeClass;
    }
    
    setIsDarkMode(!isDarkMode);
    localStorage.setItem('theme', newThemeClass);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsSidebarOpen(isOpen);
  }, [isOpen, setIsSidebarOpen]);

  const onQueueTaskComplete = () => {
    fetchFilesForPerson(selectedPersonId);
    fetchPersonFilesUpdateStatus();
    console.log('Queue task completed');
  };

  return (
    <div className={`sidebar-container ${isOpen ? 'open' : ''}`}>
      <div className={`sidebar ${isOpen ? 'open' : ''}`} style={{ top: Math.max(headerHeight - scrollPosition, 0) }}>
        <div className="sidebar-header">
          <button className="toggle-btn" onClick={toggleSidebar}>
            {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
        </div>
        <div className='sidebar-content'>
          <button 
            className="theme-toggle-btn" 
            onClick={() => navigate('/control')}
            style={{ fontSize: '24px' }}
          >
            <FaArrowLeft style={{ marginRight: '8px' }} />
          </button>
          <button 
            className="theme-toggle-btn" 
            onClick={toggleTheme} 
            style={{ fontSize: '24px' }}
          >
            {isDarkMode ? <FaSun /> : <FaMoon />}
          </button>
        </div>
        <ul className="sidebar-menu">
          <li className={isActive("/dashboard") ? "active" : ""}>
            <Link to="/dashboard" className="sidebar-link">
              Dashboard
            </Link>
          </li>
          <li className={isActive("/people") ? "active" : ""}>
            <Link to="/people" className="sidebar-link">
              People
            </Link>
          </li>
          <li className={isActive("/files") ? "active" : ""}>
            <Link to="/files" className="sidebar-link">
              Files
            </Link>
          </li>
          <li className={isActive("/admin") ? "active" : ""}>
            <Link to="/admin" className="sidebar-link">
              Admin
            </Link>
          </li>
        </ul>
        <QueueSidebar 
          isFilesQueued={isFilesQueued} 
          setIsFilesQueued={setIsFilesQueued} 
          onTaskComplete={onQueueTaskComplete} 
        />
        <ul className="sidebar-bottommenu" style={{ bottom: Math.max(headerHeight - scrollPosition, 0) }}>
          <li className="sidebar-link">Logout</li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
