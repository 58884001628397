// src/control/Control.js
import React from 'react';
import './Control.css';
import { useNavigate } from 'react-router-dom';

const Control = () => {
  const navigate = useNavigate();

  const openControlVitalise = () => {
    navigate('/dashboard');
  };

  return (
    <div className="control-panel">
      <h1 className="text">Control Panel</h1>
      <table className="control-table">
        <thead>
          <tr className="text">
            <th>Projects</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text">
            <td>VitaliseAI</td>
            <td><button className="control-button" onClick={openControlVitalise}>Open Control Panel</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Control;
