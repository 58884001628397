// Boxes.js
import React from "react";
import { motion } from "framer-motion";

export const Boxes = React.memo(function Boxes({ className, ...rest }) {
    const rows = new Array(150).fill(1);
    const cols = new Array(100).fill(1);
    let colors = [
        "#0BFDFD", // Bright Cyan for a sky blue alternative
        "#FF00FF", // Neon Pink, replacing the softer pink
        "#00FF00", // Electric Green for light green
        "#FFFF00", // Neon Yellow, more saturated than light yellow
        "#FF4500", // Neon Orange, as a more vivid version of tomato red
        "#FF00BF", // Bright Magenta for orchid
        "#00BFFF", // Bright Blue, keeping a light blue with more saturation
        "#9400D3", // Electric Indigo, deeper than original indigo
        "#C71585", // Vivid Violet, for a more neon-like appearance
    ];

  const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div
        style={{
            transform: `translate(-40%,-60%) skewX(-48deg) skewY(14deg) scale(0.675) rotate(0deg) translateZ(0)`,
        }}
        className={`boxes-container ${className}`}
        {...rest}
    >
      {rows.map((_, i) => (
        <motion.div
            key={`row-${i}`}
            className="box-row"
        >
        {cols.map((_, j) => (
            <motion.div
                whileHover={{
                    backgroundColor: getRandomColor(),
                    transition: { duration: 0.1 },
                }}
                animate={{
                    transition: { duration: 2 },
                }}
                key={`col-${j}`}
                className="box-col"
            />
        ))}
        </motion.div>
      ))}
    </div>
  );
});
