// Footer.js
import React from 'react';


const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <p className="text-muted">
          &copy; {currentYear} Mikras Ventures ApS
        </p>
        <p className="text-muted">CVR: 44658054</p>
      </div>
    </footer>
  );
};

export default Footer;
