// src/control/Admin.js
import React, { useState, useEffect, useCallback } from 'react';
import './Admin.css';
import { FaTools, FaCogs, FaDatabase, FaUser, FaChartBar, FaEnvelope, FaServer, FaFileAlt, FaHistory, FaEllipsisV } from 'react-icons/fa';

import Sidebar from './components/Sidebar';
import DragDropComponent from './components/DragDropComponent';

import createChangelogChartConfig from './config/changelogChartConfig';

// Admin Sections
import DataStatsSection from './adminsections/DataStatsSection';
import CommunicationSection from './adminsections/CommunicationSection';

import axios from './helpers/axiosWithAuth';

const categories = [
  { id: 1, title: 'Troubleshooting', icon: <FaTools /> },
  { id: 2, title: 'Configuration', icon: <FaCogs /> },
  { id: 3, title: 'Functions', icon: <FaFileAlt /> },
  { id: 4, title: 'Staging', icon: <FaDatabase /> },
  { id: 5, title: 'User Management', icon: <FaUser /> },
  { id: 6, title: 'Monitoring', icon: <FaServer /> },
  { id: 7, title: 'Data & Stats', icon: <FaChartBar /> },
  { id: 8, title: 'Communication', icon: <FaEnvelope /> },
  { id: 9, title: 'Admin Changelog', icon: <FaHistory /> },
];

const userColumns = [
  'id', 'name', 'surname', 'email', 'user_version', 'new_user', 'language',
  'display_setting', 'voice_setting', 'voice_speed_setting', 'autoplaybackaudio_setting'
];

const Admin = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  
  const [isViewingPeopleRanking, setIsViewingPeopleRanking] = useState(false);
  const [people, setPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [changelogData, setChangelogData] = useState([]);

  const [appUsers, setAppUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});

  const filterableColumns = ['user_version', 'new_user', 'language', 'display_setting', 'voice_setting', 'autoplaybackaudio_setting'];

  const renderCategory = (category) => (
    <div key={category.id} className="category-container" onClick={() => handleCategoryChange(category)}>
      <div className="category-icon">{category.icon}</div>
      <h4 className="category-title">{category.title}</h4>
    </div>
  );

  const handleCategoryChange = (category) => {
    setSelectedCategory(category.title);
    setIsViewingPeopleRanking(false);
  }


  const handleViewPeopleRanking = () => {
    setIsViewingPeopleRanking(!isViewingPeopleRanking);
  };

  const fetchPeopleRanking = useCallback(() => {
    setIsLoading(true);
    axios.get(`${API_BASE_URL}/get-people-rankings`)
      .then(response => {
        const sortedData = response.data.sort((a, b) => a.default_ranking - b.default_ranking);
        setPeople(sortedData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Failed to fetch people:", error);
        setIsLoading(false);
      });
  }, [API_BASE_URL]);
  

  const updateRankings = async () => {
    setIsLoading(true);
    const updatedRankings = people.map((person, index) => ({
      id: person.id,
      name: person.name,
      surname: person.surname,
      default_ranking: index + 1,
    }));
    try {
      console.log("Updated rankings being sent:", updatedRankings);
      const response = await axios.post(`${API_BASE_URL}/update-rankings`, { people: updatedRankings });
      console.log('Update response:', response);
      alert('Rankings updated successfully');
      setPeople(updatedRankings);
      fetchPeopleRanking();
    } catch (error) {
      console.error('Failed to update rankings:', error);
      alert('Failed to update rankings: ' + error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isViewingPeopleRanking) {
      fetchPeopleRanking();
    }
  }, [isViewingPeopleRanking, fetchPeopleRanking]);

  const handleClearQueue = () => {
    try {
      axios.post(`${API_BASE_URL}/clear-redis-database`)
        .then(response => {
          alert(response.data.message);
        })
        .catch(error => {
          console.error("Error clearing the queue:", error);
          alert("Failed to clear the queue.");
        });
    } catch (error) {
      console.error("Error clearing the queue:", error);
      alert("Failed to clear the queue.");
    }
  };

  const handleClearTempFiles = () => {
    try {
      axios.post(`${API_BASE_URL}/clear-temp-files`)
        .then(response => {
          alert(response.data.message);
        })
        .catch(error => {
          console.error("Error clearing the temp files:", error);
          alert("Failed to clear the temp files.");
        });
    } catch (error) {
      console.error("Error clearing the temp files:", error);
      alert("Failed to clear the temp files.");
    }
  };

  const handlePushPeopleToApp = () => {
    const confirmation = window.confirm("Are you sure you want to update the main People database with staging data?");
    if (confirmation) {
      axios.post(`${API_BASE_URL}/update-people-from-staging`, {}, {
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(response => {
        const data = response.data;
        if (data.success) {
          alert('Database has been successfully updated.');
        } else {
          alert('Failed to update database: ' + data.message);
        }
      })
      .catch(error => {
        console.error('Error updating database:', error);
        alert('Error updating database: ' + error.message);
      });
    }
  };

  const handlePushFilesToApp = () => {
    const confirmation = window.confirm("Are you sure you want to update the main Files database with staging data?");
    if (confirmation) {
      axios.post(`${API_BASE_URL}/update-files-from-staging`, {}, {
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(response => {
        const data = response.data;
        if (data.success) {
          alert('Storage has been successfully updated.');
        } else {
          alert('Failed to update storage: ' + data.message);
        }
      })
      .catch(error => {
        console.error('Error updating storage:', error);
        alert('Error updating storage: ' + error.message);
      });
    }
  };

  const fetchAppUsers = useCallback(() => {
    axios.get(`${API_BASE_URL}/get-app-users`)
      .then(response => {
        setAppUsers(response.data);
        setFilteredUsers(response.data);
        // Initialize filter options
        const options = {};
        filterableColumns.forEach(column => {
          options[column] = new Set(response.data.map(user => user[column] !== null ? user[column].toString() : 'N/A'));
        });
        setFilterOptions(options);
      })
      .catch(error => {
        console.error("Failed to fetch app users:", error);
      });
  }, [API_BASE_URL]);

  const handleFilterChange = (column, value, checked) => {
    setFilterOptions(prevOptions => {
      const newOptions = { ...prevOptions };
      if (checked) {
        newOptions[column].add(value);
      } else {
        newOptions[column].delete(value);
      }
      return newOptions;
    });
  
    setFilteredUsers(prevUsers => {
      return appUsers.filter(user => {
        return filterableColumns.every(column => {
          const activeFilters = Array.from(filterOptions[column]);
          return activeFilters.length === 0 || activeFilters.includes(user[column] !== null ? user[column].toString() : 'N/A');
        });
      });
    });
  };

  const handleSortColumn = (column) => {
    setFilteredUsers(prevUsers => {
      const sortedUsers = [...prevUsers].sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      return sortedUsers;
    });
  };

  const deleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await axios.delete(`${API_BASE_URL}/delete-app-user/${userId}`);
        if (response.status === 200) {
          setFilteredUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
          setAppUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
          alert('User deleted successfully');
        } else {
          throw new Error(response.data.error || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert(error.response?.data?.error || 'Failed to delete user');
      }
    }
  };

  // Admin Changelogs
  const fetchAdminChangelog = () => {
    axios.get(`${API_BASE_URL}/get-admin-changelog`)
      .then(response => {
        setChangelogData(response.data);
      })
      .catch(error => {
        console.error("Failed to fetch admin changelog:", error);
      });
  };

  useEffect(() => {
    if (selectedCategory === 'Admin Changelog') {
      fetchAdminChangelog();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (changelogData.length > 0) {
      createChangelogChartConfig(changelogData); // Initialize the chart
    }
  }, [changelogData]);

  return (
    <div className="admin">
      <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
      <div className={`admin-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <h1 className='page-title'>Admin Panel</h1>

        <div className="categories-row">
          {categories.map(renderCategory)}
        </div>
        {selectedCategory && (
          <div className="category-details">
            <h2>{selectedCategory}</h2>

            {/* Troubleshooting */}
            {selectedCategory === 'Troubleshooting' && (
              <div className="admin-subsection">
                <button className="wide-button" onClick={handleClearQueue}>Clear Redis Queue</button>
                <button className="wide-button" onClick={handleClearTempFiles}>Clear Temp Files</button>
                <p>Restart Celery?</p>
                <p>A button to do all of the above in succession onClick?</p>
              </div>
            )}

            {/* Configuration */}

            {selectedCategory === 'Configuration' && (

              <div className="admin-subsection">
                <button className="wide-button" onClick={handleViewPeopleRanking}>
                  {isViewingPeopleRanking ? 'Close' : 'Rank People'}
                </button>
                {isViewingPeopleRanking && (
                  <div className="ranking-section">
                      <button className="widest-button" onClick={updateRankings} disabled={isLoading}>
                        Update Rankings
                      </button>
                      {isLoading ? (
                        <p>Loading people...</p>
                      ) : (
                        <>
                          {people.length > 0 ? (
                            <DragDropComponent people={people} onReorder={setPeople} />
                          ) : (
                            <p>No people data available. Please check the database connection or API endpoint.</p>
                          )}
                        </>
                      )}
                  </div>
                )}
                <p>Function Points Conversion</p>
              </div>
            )}


            {/* Functions */}
            {selectedCategory === 'Functions' && (

              <p>Generate All Assistant Files</p>
            )}

            {/* Staging */}
            {selectedCategory === 'Staging' && (
              <div className="admin-subsection">
                <button className="wide-button" onClick={handlePushPeopleToApp}>Push People Profiles to App</button>
                <button className="wide-button" onClick={handlePushFilesToApp}>Push Files to App</button>
              </div>
            )}

            {/* User Management */}
            {selectedCategory === 'User Management' && (
              <div>
                <button className="wide-button" onClick={fetchAppUsers}>See App Users</button>
                {filteredUsers.length > 0 && (
                  <table className="user-table">
                    <thead>
                      <tr>
                        <th>Actions</th>
                        {userColumns.map(column => (
                          <th key={column} onClick={() => handleSortColumn(column)}>
                            {column}
                            {filterableColumns.includes(column) && (
                              <div className="filter-options">
                                {Array.from(filterOptions[column] || []).map(value => (
                                  <label key={value}>
                                    <input
                                      type="checkbox"
                                      checked={filterOptions[column].has(value)}
                                      onChange={(e) => handleFilterChange(column, value, e.target.checked)}
                                    />
                                    {value}
                                  </label>
                                ))}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map(user => (
                        <tr key={user.id}>
                          <td>
                            <div className="action-dropdown">
                              <FaEllipsisV />
                              <div className="dropdown-content">
                                <button onClick={() => deleteUser(user.id)}>Delete user</button>
                                <button onClick={() => console.log('Update user', user.id)}>Update</button>
                              </div>
                            </div>
                          </td>
                          {userColumns.map(key => (
                            <td key={key}>{user[key] !== null ? user[key].toString() : 'N/A'}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}

            {/* Monitoring */}
            {selectedCategory === 'Monitoring' && (
              <div>
                <p>Servers</p>
                <p>Databases</p>
                <a href="https://logs.betterstack.com/team/264453/tail?rf=now-60m" target="_blank" rel="noopener noreferrer">Logs</a>
              </div>
            )}

            {/* Data & Stats */}
            {selectedCategory === 'Data & Stats' && (
              <div>
                <DataStatsSection />
              </div>
            )}

            {/* Communication */}
            {selectedCategory === 'Communication' && (
              <div>
                <CommunicationSection />
              </div>
            )}

            {/* Admin Changelog */}
            {selectedCategory === 'Admin Changelog' && (
              <div>
                <p>Online Activity</p>
                <canvas id="onlineActivityChart" width="400" height="50"></canvas>

                <p>All Activity</p>
                <canvas id="activityTimelineChart" width="400" height="40"></canvas>

                <p>People Added</p>
                <canvas id="peopleAddedChart" width="400" height="50"></canvas>

                <p>People Modified</p>
                <canvas id="peopleModifiedChart" width="400" height="50"></canvas>

                <p>People Deleted</p>
                <canvas id="peopleDeletedChart" width="400" height="50"></canvas>

                <p>Files Transcribed</p>
                <canvas id="filesTranscribedChart" width="400" height="50"></canvas>
                
                <p>Documents Added</p>
                <canvas id="documentsAddedChart" width="400" height="50"></canvas>

                <p>Files Deleted</p>
                <canvas id="filesDeletedChart" width="400" height="50"></canvas>

                <p>Assistant Files Generated</p>
                <canvas id="assistantFilesGeneratedChart" width="400" height="50"></canvas>

              </div>
            )}
                    
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;