// src/control/config/filesInfoChartConfig.js

// src/config/filesInfoChartConfig.js

export const getFilesInfoChartsConfig = (filesInfo) => {
  const sortedFilesInfoByCount = [...filesInfo].sort((a, b) => b.files_count - a.files_count);
  const sortedFilesInfoByCharacters = [...filesInfo].sort((a, b) => b.total_characters - a.total_characters);

  const dataForFilesCountChart = {
    labels: sortedFilesInfoByCount.map(info => `${info.name} ${info.surname} (${info.person_id})`),
    datasets: [{
      label: '# of Files',
      data: sortedFilesInfoByCount.map(info => info.files_count),
      backgroundColor: sortedFilesInfoByCount.map((info, index, arr) => {
        const gradient = index / (arr.length - 1);
        const startColor = { r: 128, g: 222, b: 234 };
        const endColor = { r: 255, g: 216, b: 181 };
        const r = startColor.r + gradient * (endColor.r - startColor.r);
        const g = startColor.g + gradient * (endColor.g - startColor.g);
        const b = startColor.b + gradient * (endColor.b - startColor.b);
        return `rgb(${r},${g},${b})`;
      }),
    }],
  };

  const optionsForFilesCountChart = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => '# of Files',
          label: function(context) {
            const labelIndex = context.dataIndex;
            const info = sortedFilesInfoByCount[labelIndex];
            return `${info.name} ${info.surname} (ID: ${info.person_id}): ${info.files_count} files`;
          }
        }
      }
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  const dataForTotalCharactersChart = {
    labels: sortedFilesInfoByCharacters.map(info => `${info.name} ${info.surname} (${info.person_id})`),
    datasets: [{
      label: 'Total Characters',
      data: sortedFilesInfoByCharacters.map(info => info.total_characters),
      backgroundColor: sortedFilesInfoByCharacters.map((info, index, arr) => {
        const gradient = index / (arr.length - 1);
        const startColor = { r: 128, g: 222, b: 234 };
        const endColor = { r: 255, g: 216, b: 181 };
        const r = startColor.r + gradient * (endColor.r - startColor.r);
        const g = startColor.g + gradient * (endColor.g - startColor.g);
        const b = startColor.b + gradient * (endColor.b - startColor.b);
        return `rgb(${r},${g},${b})`;
      }),
    }],
  };

  const optionsForTotalCharactersChart = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: () => 'Total Characters',
          label: function(context) {
            const labelIndex = context.dataIndex;
            const info = sortedFilesInfoByCharacters[labelIndex];
            return `${info.name} ${info.surname} (ID: ${info.person_id}): ${info.total_characters} characters`;
          }
        }
      }
    },
    maintainAspectRatio: false,
  };

  return {
    dataForFilesCountChart,
    optionsForFilesCountChart,
    dataForTotalCharactersChart,
    optionsForTotalCharactersChart
  };
};
