// src/control/components/QueueSidebar.js
import React, { useEffect, useState, useCallback, useContext } from 'react';
import './QueueSidebar.css';
import { FaFileAlt } from 'react-icons/fa';
import { QueueContext } from '../../context/QueueContext';

import axios from '../helpers/axiosWithAuth';

const QueueSidebar = ({ onTaskComplete }) => {
  const { isFilesQueued, setIsFilesQueued } = useContext(QueueContext);
  const [queueStatus, setQueueStatus] = useState([]);
  const [emptyPollsCount, setEmptyPollsCount] = useState(0);
  const [previousTaskCount, setPreviousTaskCount] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const handleTaskComplete = useCallback(async () => {
    console.log('handleTaskComplete called');
    onTaskComplete();
  }, [onTaskComplete]);

  useEffect(() => {
    let intervalId;
  
    if (isFilesQueued) {
      intervalId = setInterval(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/queue-status`);
          const tasks = response.data;
  
          setQueueStatus(tasks);
  
          if (tasks.length === 0) {
            const newCount = emptyPollsCount + 1;
            console.log('No active tasks, incrementing emptyPollsCount to', newCount);
            setEmptyPollsCount(newCount);
            if (newCount >= 3) {
              console.log('No active tasks for 3 polls, stopping polling');
              setIsFilesQueued(false);
              clearInterval(intervalId);
              //handleTaskComplete();
            }
          } else {
            setEmptyPollsCount(0);
          }
          if (tasks.length < previousTaskCount) {
            handleTaskComplete();
          }
          setPreviousTaskCount(tasks.length); // Update the previous task count
        } catch (error) {
          console.error('Error fetching queue status', error);
          setIsFilesQueued(false);
          clearInterval(intervalId);
        }
      }, 5000);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [API_BASE_URL, emptyPollsCount, previousTaskCount, isFilesQueued, setIsFilesQueued, handleTaskComplete]);
  

  return (
    <div className="queue-sidebar">
      <div className="queue-title">Queued Jobs</div>
      {queueStatus.length === 0 ? (
        <div className="no-jobs-message">No jobs in queue</div>
      ) : (
        <>
          {queueStatus.slice(0, 5).map((task, index) => (
            <div key={task.task_id} className={`queue-item ${index === 0 ? 'first' : ''}`}>
              <FaFileAlt className={`queue-file-icon ${index === 0 && task.status === 'processing' ? 'processing' : ''}`} />
              <div className="file-info">
                <div className="file-name">{task.originalFilename}</div>
                {index === 0 && task.status === 'processing' && (
                  <div className="processing-text">Processing... {task.chunksProcessed}/{task.totalChunks}</div>
                )}
              </div>
            </div>
          ))}
          {queueStatus.length > 5 && (
            <div className="more-jobs-message">+ {queueStatus.length - 5} more jobs</div>
          )}
        </>
      )}
    </div>
  );
};

export default QueueSidebar;
