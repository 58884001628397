// src/control/People.js
import React, { useState, useEffect, useCallback } from 'react';
import './People.css';

import Sidebar from './components/Sidebar';
import { FaUser, FaRobot, FaPlus, FaMinus, FaEdit, FaTrash, FaTimes, FaSave, FaTimesCircle, FaGlobe, FaYoutube, FaInstagram, FaTiktok, FaSpotify } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

import categoriesOfExpertise from './config/expertiseCategories';

import axios from './helpers/axiosWithAuth';

const People = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);;
  const [imageFile, setImageFile] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [people, setPeople] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('ID');
  const [peopleSearchInput, setPeopleSearchInput] = useState('');
  const [isLoadingPeople, setIsLoadingPeople] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [shortBio, setShortBio] = useState('');
  const [longBio, setLongBio] = useState('');
  const categories_of_expertise = categoriesOfExpertise;
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [showAddPersonForm, setShowAddPersonForm] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  const [socialLinks, setSocialLinks] = useState([]);

  const sortOptions = ['ID', 'Alphabetical'];
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [initialSelectedExpertise, setInitialSelectedExpertise] = useState([]);
  const [tempSelectedExpertise, setTempSelectedExpertise] = useState([...categories_of_expertise.flatMap(category => category.items)]);

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setCurrentFilter(selectedFilter);
    
    let sortedList = [];
    if (selectedFilter === 'Alphabetical') {
      sortedList = [...peopleList].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedList = [...peopleList].sort((a, b) => a.id - b.id);
    }
    setPeopleList(sortedList);
  };  

  const fetchPeopleData = useCallback(async () => {
    setIsLoadingPeople(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/get-people`);
      if (response.data) {
        const updatedPeopleData = response.data.map(person => ({
          ...person,
          image_url: person.has_image ? `${person.image_url}?v=${Date.now()}` : 'path_to_placeholder_image'
        }));
        setPeople(updatedPeopleData);
        setPeopleList(updatedPeopleData);  // Set peopleList without sorting
        setIsLoadingPeople(false);
      }
    } catch (error) {
      console.error("Failed to fetch people:", error);
      setIsLoadingPeople(false);
    }
  }, [API_BASE_URL]);  

  useEffect(() => {
      fetchPeopleData();
  }, [fetchPeopleData]);

  const toggleImageModal = () => {
    setIsImageModalOpen(!isImageModalOpen);
    setImageSelected(false);
  };

  const handleImageUpload = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageFile(file);
      setImageSelected(true);
      console.log("Image file uploaded:", file);
    }
  };

  const handleSubmitImage = () => {
    if (!imageFile) {
      alert('Please select an image to upload.');
      return;
    }
    setImageSelected(true);
    const formData = new FormData();
    formData.append('image', imageFile);
    setImageSelected(true);
    setIsImageModalOpen(false);
  };

  const resetForm = () => {
    setName('');
    setSurname('');
    setShortBio('');
    setLongBio('');
    setSocialLinks([]);
    setIsEditMode(false);
    setImageSelected(false);
  };

  const selectPersonFromSearch = (id) => {
    setSelectedPersonId(id);
    setPeopleSearchInput('');
    setShowAddPersonForm(false);
    setIsEditMode(false);
    setImageSelected(false);
  };

  const toggleAddPersonForm = () => {
    setShowAddPersonForm(!showAddPersonForm);
    setSelectedPersonId('');
    setSelectedExpertise([]);
    setImageSelected(false);
    resetForm();
  };

  const toggleFilterModal = () => {
    if (!isFilterModalOpen) {
      setInitialSelectedExpertise([...tempSelectedExpertise]);
    }
    setTempSelectedExpertise([...tempSelectedExpertise]);
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleSelectAll = () => {
    setTempSelectedExpertise(categories_of_expertise.flatMap(category => category.items));
  };

  const handleDeselectAll = () => {
    setTempSelectedExpertise([]);
  };

  const handleTempExpertiseChange = (expertise) => {
    if (tempSelectedExpertise.includes(expertise)) {
      setTempSelectedExpertise(tempSelectedExpertise.filter(item => item !== expertise));
    } else {
      setTempSelectedExpertise([...tempSelectedExpertise, expertise]);
    }
  };

  const handleCancelFilters = () => {
    setTempSelectedExpertise([...initialSelectedExpertise]);
    setIsFilterModalOpen(false);
  };

  const handleApplyFilters = () => {
    setTempSelectedExpertise(tempSelectedExpertise);
    setIsFilterModalOpen(false);
    if (tempSelectedExpertise.length === 0) {
        setPeopleList([]);
    } else {
        setPeopleList(people.filter(person => tempSelectedExpertise.some(expertise => person.categories_of_expertise.includes(expertise))));
    }
  };

  const getFilterButtonText = () => {
    if (tempSelectedExpertise.length === categories_of_expertise.flatMap(category => category.items).length) {
        return 'Filter (all)';
    } else if (tempSelectedExpertise.length === 0) {
        return 'Filter (none)';
    } else {
        return 'Filter (custom)';
    }
  };

  useEffect(() => {
    const filterPeople = () => {
      if (tempSelectedExpertise.length === 0) {
        setPeopleList(people);
      } else {
        const filtered = people.filter(person =>
            person.categories_of_expertise.some(expertise => tempSelectedExpertise.includes(expertise))
        );
        setPeopleList(filtered);
      }
    };

    filterPeople();
  }, [tempSelectedExpertise, people]);

  const handlePeopleSearchChange = (e) => {
    const input = e.target.value.toLowerCase();
    setPeopleSearchInput(input);
    
    if (input.trim() !== '') {
        const filtered = people.filter(person =>
            `${person.name} ${person.surname}`.toLowerCase().includes(input)
        );
        setPeopleList(filtered);
    } else {
        setPeopleList(people);  // Reset to the original list if search input is empty
    }
  };

  const handleAddNewPerson = async () => {
    if (!name || !surname || !shortBio || !longBio || !imageSelected) {
      alert("All fields are required, including an image.");
      return;
    }
    console.log("Adding new person...");
    try {
      const personResponse = await axios.post(`${API_BASE_URL}/add-person`, {
        name,
        surname,
        short_bio: shortBio,
        long_bio: longBio,
        categories_of_expertise: selectedExpertise,
        social_links: socialLinks
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log("Person added, response:", personResponse.data);
      console.log("imageFile state at upload time:", imageFile);
      if (personResponse.data && personResponse.data.id && imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('person_id', personResponse.data.id);
        console.log("Uploading image for person ID:", personResponse.data.id);
        const imageResponse = await axios.post(`${API_BASE_URL}/add-image`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log("Image upload response:", imageResponse.data);
        if (imageResponse.status === 200) {
          alert("Person and image added successfully!");
          resetForm();
          fetchPeopleData();
        } else {
          alert("Failed to upload image.");
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert("There was an error adding the person or uploading the image.");
    }
  };

  const handleGenerateBios = () => {
    if (!name || !surname) {
      alert('Please fill in both name and surname.');
      return;
    }
    axios.post(`${API_BASE_URL}/generate-bios`, { name, surname })
      .then(response => {
        const { shortBio, longBio } = response.data;
        setShortBio(shortBio);
        setLongBio(longBio);
      })
      .catch(error => console.error("Error generating bios:", error));
  };

  const handleExpertiseChange = (category) => {
    const isSelected = selectedExpertise.some(item => item === category);
    if (isSelected) {
      setSelectedExpertise(selectedExpertise.filter(item => item !== category));
    } else {
      setSelectedExpertise([...selectedExpertise, category]);
    }
  };
  
  const handleCancelEdit = () => {
    setIsEditMode(false);
    fetchPersonDetails(selectedPersonId);
  };

  const fetchPersonDetails = useCallback(async (personId) => {
    if (personId) {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-person/${personId}`);
        const { name, surname, short_bio, long_bio, categories_of_expertise, social_links } = response.data;
        setName(name);
        setSurname(surname);
        setShortBio(short_bio);
        setLongBio(long_bio);
        setSelectedExpertise(categories_of_expertise || []);
        setInitialSelectedExpertise(categories_of_expertise || []);
        setSocialLinks(social_links || []);
      } catch (error) {
        console.error("There was an error fetching the person details: ", error);
      }
    } else {
      resetForm();
    }
  }, [API_BASE_URL]);

  useEffect(() => {
      fetchPersonDetails(selectedPersonId);
  }, [selectedPersonId, fetchPersonDetails]);

  const handleSave = async () => {
    try {
      // Check if name, surname, shortBio, LongBio or socialLinks have changed
      const selectedPerson = people.find(person => person.id === selectedPersonId);
      const hasDetailsChanged = (
        selectedPerson.name !== name ||
        selectedPerson.surname !== surname ||
        selectedPerson.short_bio !== shortBio ||
        selectedPerson.long_bio !== longBio ||
        selectedPerson.social_links !== socialLinks
      );

      const personResponse = await axios.put(`${API_BASE_URL}/update-person/${selectedPersonId}`, {
        name,
        surname,
        short_bio: shortBio,
        long_bio: longBio,
        categories_of_expertise: selectedExpertise,
        social_links: socialLinks,
        log_update: hasDetailsChanged
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      console.log("Person updated, response:", personResponse.data);
      alert("Profile successfully saved!");

      // Check if the expertise categories have changed
      const expertiseChanged = JSON.stringify(selectedExpertise) !== JSON.stringify(initialSelectedExpertise);
      if (expertiseChanged) {
        // Trigger update of categories_of_expertise_available in associated files
        await axios.post(`${API_BASE_URL}/update-file-categories`, { person_id: selectedPersonId });
        console.log("Files' categories_of_expertise_available updated successfully.");
      }

      if (imageSelected && imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('person_id', selectedPersonId);
        const imageResponse = await axios.post(`${API_BASE_URL}/update-image`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log("Image upload response:", imageResponse.data);
        if (imageResponse.status === 200) {
          fetchPeopleData();
          setIsEditMode(false);
        } else {
          alert("Failed to upload image.");
        }
      } else {
        fetchPeopleData();
        setIsEditMode(false);
      }
    } catch (error) {
      console.error('Error:', error);
      alert("There was an error saving the changes.");
    }
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm("Are you sure you want to delete this person and all their files?");
    if (isConfirmed) {
      axios.delete(`${API_BASE_URL}/delete-person/${selectedPersonId}`)
        .then(response => {
          alert("Person deleted successfully!");
          setSelectedPersonId('');
          resetForm();
          fetchPeopleData();
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            alert(error.response.data.error);
          } else {
            alert("There was an error deleting the person.");
          }
        });
    }
  };

  const handleAddSocialLink = () => {
    if (socialLinks.length < 5) {
      setSocialLinks([...socialLinks, { type: '', url: '' }]);
    }
  };

  const handleRemoveSocialLink = (index) => {
    const updatedLinks = socialLinks.filter((_, i) => i !== index);
    setSocialLinks(updatedLinks);
  };

  const handleSocialLinkChange = (index, field, value) => {
    const updatedLinks = [...socialLinks];
    updatedLinks[index][field] = value;
    setSocialLinks(updatedLinks);
  };

  const renderSocialLinkInputs = () => {
    return socialLinks.map((link, index) => (
      <div key={index} className="social-link-input">
        <select
          value={link.type}
          onChange={(e) => handleSocialLinkChange(index, 'type', e.target.value)}
        >
          <option value="">Select Type</option>
          <option value="website">Website</option>
          <option value="youtube">YouTube</option>
          <option value="instagram">Instagram</option>
          <option value="twitter">X (Twitter)</option>
          <option value="tiktok">TikTok</option>
          <option value="spotify">Spotify</option>
        </select>
        <input
          type="text"
          placeholder="URL"
          value={link.url}
          onChange={(e) => handleSocialLinkChange(index, 'url', e.target.value)}
        />
        <FaMinus className="remove-social-link" onClick={() => handleRemoveSocialLink(index)} />
      </div>
    ));
  };

  const renderSocialLinks = () => {
    return socialLinks.map((link, index) => (
      <div key={index} className="social-link">
        {renderSocialIcon(link.type)}
        <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
      </div>
    ));
  };

  const renderSocialIcon = (type) => {
    switch (type) {
      case 'website':
        return <FaGlobe />;
      case 'youtube':
        return <FaYoutube />;
      case 'instagram':
        return <FaInstagram />;
      case 'twitter':
        return <FaXTwitter />;
      case 'tiktok':
        return <FaTiktok />;
      case 'spotify':
        return <FaSpotify />;
      default:
        return null;
    }
  };

  const renderPerson = (person) => {
    return (
      <div key={person.id} className="person-container" onClick={() => selectPersonFromSearch(person.id)}>
        <div>
          <img
            src={person.image_url}
            alt={`${person.name} ${person.surname}`}
            className="person-image"
          />
          <div className="person-details">
            <h4 className="person-id">{person.id}</h4>
            <h4 className="person-name">{person.name}</h4>
            <h4 className="person-surname">{person.surname.split(' ')[0]}</h4>
            <p className="person-subtitle">{person.short_bio}</p>
          </div>
        </div>
      </div>
    );
  };

  const selectedPerson = people.find(person => person.id === selectedPersonId);

  return (
    <div className="people">
      <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
      <div className={`people-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <h1 className='page-title'>Manage People</h1>

        <div className="configuration-selectors">
          <select className="dropdown-selector" onChange={handleFilterChange} value={currentFilter}>
            {sortOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
          <input
            type="search"
            placeholder="Search by name or surname..."
            className="search-input"
            value={peopleSearchInput}
            onChange={handlePeopleSearchChange}
          />
          <button className="dropdown-button" onClick={toggleFilterModal}>
            {getFilterButtonText()}
          </button>
          {isFilterModalOpen && (
            <div className="expertise-modal">
              <div className="expertise-modal-content">
                <div className="config-icons">
                  <FaTimes title="Cancel" className='config-icon' onClick={handleCancelFilters} />
                  <FaSave title="Apply" className='config-icon' onClick={handleApplyFilters} />
                </div>
                <h3>Filter by Expertise</h3>
                <div className="modal-buttons">
                  <button className="wide-button" onClick={handleSelectAll}>Select All</button>
                  <button className="wide-button" onClick={handleDeselectAll}>Deselect All</button>
                </div>
                <div className="expertise-categories-container">
                  {categories_of_expertise.map(category => (
                    <div key={category.id} className="expertise-category">
                      <strong>{category.category}</strong>
                      <div>
                        {category.items.map(item => (
                          <button
                            key={item}
                            type="button"
                            className={tempSelectedExpertise.includes(item) ? "expertise-selected" : "expertise-not-selected"}
                            onClick={() => handleTempExpertiseChange(item)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
          </div>
        )}
        </div>

        <div className="configuration-section">
          <div className="people-slider">
            <div className="add-person-icon-container">
              <div className="add-person-icon" onClick={toggleAddPersonForm}>
                <FaPlus size={32} />
              </div>
            </div>
            {isLoadingPeople ? (
                <div className="no-files-message">Loading people...</div>
            ) : (
                peopleList.map(person => renderPerson(person))
            )}
          </div>
        </div>

        <div className="add-person-section">
          {showAddPersonForm && (
            <div className="add-person-form">
              <div className="config-icons">
                <FaSave title="Save" className='config-icon' onClick={handleAddNewPerson} />
                <FaTimes title="Close" className='config-icon' onClick={toggleAddPersonForm} />
              </div>
              <h3 className="subsection-selection-title">Add New Person</h3>
              <div className="person-row">
                <div className="person-icon-container">
                <FaUser
                  className={`person-add-image ${imageSelected ? 'image-selected' : 'image-not-selected'}`}
                  onClick={toggleImageModal}
                />
                  {isImageModalOpen && (
                    <div className="image-modal">
                      <div className="image-modal-content">
                        <div className="config-icons">
                          <FaTimes title="Close" className='config-icon' onClick={toggleImageModal} />
                        </div>
                        <h3 className="subsection-selection-title">Upload Image</h3>
                        <p className="text">1. Download image of person</p>
                        <a href="https://imageresizer.com/" target="_blank" className="text" rel="noopener noreferrer">2. Crop/Resize to 150x150px here</a>
                        <p className="text">3. Select and upload resized image below:</p>
                        <div className="image-submit-container">
                          <label
                            className={`image-upload-label ${imageSelected ? 'image-selected' : ''}`}
                          >
                            {imageSelected ? 'Image selected' : 'Choose image...'}
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleImageUpload}
                              style={{ display: 'none' }}
                            />
                          </label>
                          {imageSelected && (
                            <FaSave
                              title="Submit"
                              className='submit-icon'
                              onClick={handleSubmitImage}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="input-column">
                  <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                  <input type="text" placeholder="Surname" value={surname} onChange={e => setSurname(e.target.value)} />
                </div>
              </div>
              <div className="gpt-input">
                <text className="gpt-input-text">Generate bios with AI</text>
                <FaRobot onClick={handleGenerateBios} className="gpt-input-icon" />
              </div>
              <div className="person-bio">
                <div className="bio-inputs">
                  <div className="input-title">
                    Short Bio - Characters: {shortBio.length} (max 80)
                  </div>
                  <textarea
                    placeholder="Short description"
                    value={shortBio}
                    onChange={e => setShortBio(e.target.value)}
                    maxLength={80}
                    className="short-description-textarea"
                  />
                  <div className="input-title">
                    Long Bio - Characters: {longBio.length} (max 400)
                  </div>
                  <textarea
                    placeholder="Long description"
                    value={longBio}
                    onChange={e => setLongBio(e.target.value)}
                    maxLength={400}
                    className="long-description-textarea"
                  />
                </div>
                <div className="social-links-section">
                  <h4>Socials</h4>
                  {renderSocialLinkInputs()}
                  {socialLinks.length < 5 && (
                    <FaPlus className="add-social-link" onClick={handleAddSocialLink} />
                  )}
                </div>
              </div>
              <div className='input-title'>Expertise</div>
              <div className="expertise-categories-container">
                {categories_of_expertise.map(category => (
                  <div key={category.id} className="expertise-category">
                    <strong>{category.category}</strong>
                    <div>
                      {category.items.map(item => (
                        <button
                          key={item}
                          type="button"
                          className={selectedExpertise.includes(item) ? "expertise-selected" : "expertise-not-selected"}
                          onClick={() => handleExpertiseChange(item)}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {selectedPersonId && (
          <div className="edit-person-section">
            <div className="add-person-form">
              <div className="config-icons">
                {!isEditMode ? (
                  <>
                    <FaEdit title="Edit" className='config-icon' onClick={() => setIsEditMode(true)} />
                    <FaTrash title="Delete" className='config-icon' onClick={handleDelete} />
                    <FaTimes title="Close" className='config-icon' onClick={() => setSelectedPersonId('')} />
                  </>
                ) : (
                  <>
                    <FaTimesCircle title="Cancel" className='config-icon' onClick={handleCancelEdit} />
                    <FaSave title="Save" className='config-icon' onClick={handleSave} />
                    <FaTimes title="Close" className='config-icon' onClick={() => setSelectedPersonId('')} />
                  </>
                )}
              </div>
              <h3 className="subsection-selection-title">Edit Profile</h3>
              <div className="person-row">
                <div className="person-icon-container">
                  {imageSelected || !selectedPerson.image_url ? (
                    <FaUser
                      className={`person-add-image ${imageSelected ? 'image-selected' : 'image-not-selected'}`}
                      onClick={toggleImageModal}
                    />
                  ) : (
                    <img
                      src={selectedPerson.image_url}
                      alt={`${selectedPerson.name} ${selectedPerson.surname}`}
                      className={`person-add-image ${isEditMode ? 'editable' : ''}`}
                      onClick={() => isEditMode && toggleImageModal()}
                      style={{
                        cursor: isEditMode ? 'pointer' : 'not-allowed',
                      }} 
                    />
                  )}
                  {isImageModalOpen && (
                    <div className="image-modal">
                      <div className="image-modal-content">
                        <div className="config-icons">
                          <FaTimes title="Close" className='config-icon' onClick={toggleImageModal} />
                        </div>
                        <h3 className="subsection-selection-title">Upload Image</h3>
                        <p className="text">1. Download image of person</p>
                        <a href="https://picresize.com/" target="_blank" className="text" rel="noopener noreferrer">2. Crop/Resize to 150x150px here</a>
                        <p className="text">3. Select and upload resized image below:</p>
                        <div className="image-submit-container">
                          <label
                            className={`image-upload-label ${imageSelected ? 'image-selected' : ''}`}
                          >
                            {imageSelected ? 'Image selected' : 'Choose image...'}
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleImageUpload}
                              style={{ display: 'none' }}
                            />
                          </label>
                          {imageSelected && (
                            <FaSave
                              title="Submit"
                              className='submit-icon'
                              onClick={handleSubmitImage}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="input-column">
                  <input 
                    type="text" 
                    placeholder="Name" 
                    value={name} 
                    onChange={e => setName(e.target.value)} 
                    disabled={!isEditMode} 
                    style={{
                      cursor: isEditMode ? 'text' : 'not-allowed',
                      borderColor: isEditMode ? '#80DEEA' : 'transparent' 
                    }} 
                  />
                  <input 
                    type="text" 
                    placeholder="Surname" 
                    value={surname} 
                    onChange={e => setSurname(e.target.value)} 
                    disabled={!isEditMode} 
                    style={{
                      cursor: isEditMode ? 'text' : 'not-allowed',
                      borderColor: isEditMode ? '#80DEEA' : 'transparent'
                    }} 
                  />
                </div>
              </div>
              <div className="person-bio">
                <div className="bio-inputs">
                  <div className="input-title">
                    Short Bio - Characters: {shortBio.length} (max 80)
                  </div>
                  <textarea 
                    placeholder="Short description" 
                    value={shortBio} 
                    onChange={e => setShortBio(e.target.value)} 
                    disabled={!isEditMode} 
                    style={{
                      cursor: isEditMode ? 'text' : 'not-allowed',
                      borderColor: isEditMode ? '#80DEEA' : 'transparent'
                    }} 
                    className="short-description-textarea" 
                  />
                  <div className="input-title">
                    Long Bio - Characters: {longBio.length} (max 400)
                  </div>
                  <textarea 
                    placeholder="Long description" 
                    value={longBio} 
                    onChange={e => setLongBio(e.target.value)} 
                    disabled={!isEditMode} 
                    style={{
                      cursor: isEditMode ? 'text' : 'not-allowed',
                      borderColor: isEditMode ? '#80DEEA' : 'transparent'
                    }} 
                    className="long-description-textarea" 
                  />
                </div>
                <div className="social-links-section">
                  {socialLinks.length > 0 && <h4>Socials</h4>}
                  {isEditMode ? (
                    <>
                      {renderSocialLinkInputs()}
                      {socialLinks.length < 5 && (
                        <FaPlus className="add-social-link" onClick={handleAddSocialLink} />
                      )}
                    </>
                  ) : (
                    renderSocialLinks()
                  )}
                </div>
              </div>
              <div className="expertise-categories-container">
                {categories_of_expertise.map(category => (
                  <div key={category.id} className="expertise-category" style={{cursor: isEditMode ? 'default' : 'not-allowed'}} >
                    <strong>{category.category}</strong>
                    <div >
                      {category.items.map(item => (
                        <button
                          key={item}
                          type="button"
                          className={selectedExpertise.includes(item) ? "expertise-selected" : "expertise-not-selected"}
                          onClick={() => handleExpertiseChange(item)}
                          disabled={!isEditMode}
                          style={{
                            cursor: isEditMode ? 'pointer' : 'not-allowed',
                          }} 
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default People;