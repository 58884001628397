// src/control/config/kpiChartConfig.js
import { kpiData } from '../data/kpiData';

export const dailyUserStatsData = {
  labels: kpiData.dailyUserStats.map(stat => stat.date),
  datasets: [
    {
      label: 'Free Users',
      data: kpiData.dailyUserStats.map(stat => stat.free),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      fill: true,
    },
    {
      label: 'Standard Users',
      data: kpiData.dailyUserStats.map(stat => stat.standard),
      borderColor: 'rgba(54, 162, 235, 1)',
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      fill: true,
    },
    {
      label: 'Premium Users',
      data: kpiData.dailyUserStats.map(stat => stat.premium),
      borderColor: 'rgba(255, 206, 86, 1)',
      backgroundColor: 'rgba(255, 206, 86, 0.2)',
      fill: true,
    },
  ],
};

export const dailyUserStatsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'MMM dd, yyyy',
      },
    },
    y: {
      beginAtZero: true,
    },
  },
};
