// src/control/helpers/axiosWithAuth.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor to append the auth token to every request
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  const adminId = localStorage.getItem('admin_id');
  if (adminId) {
    config.headers['admin-id'] = adminId;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true; // mark the request as retried
    try {
      const refreshToken = localStorage.getItem('refreshToken'); // Retrieve the stored refresh token
      const refreshResponse = await axios.post(`${API_BASE_URL}/token/refresh`, {}, {
        headers: {
          'Authorization': `Bearer ${refreshToken}`
        }
      });
      // Store the new tokens
      const { access_token, refresh_token } = refreshResponse.data;
      localStorage.setItem('token', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      // Update the header for the original request
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return axiosInstance(originalRequest); // Retry the original request with the new token
    } catch (refreshError) {
      console.error('Error refreshing token', refreshError);
      // Redirect to login or perform other action to handle token refresh failure
      // e.g., clear storage, redirect to login
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = '/'; // Adjust as needed
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;
